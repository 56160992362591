import React, { useMemo } from 'react';
import { Button, Menu, Skeleton, Typography } from 'antd';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import P from 'router/paths';
import useWebsites from 'modules/website/hooks/useWebsites';
import EditIcon from 'components/icons/EditIcon';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Icon from '@mdi/react';
import { mdiCheckCircle, mdiCloseCircle } from '@mdi/js';
import Ninja from '@ninja';

const ConnectedWebsitesList = (props) => {
  const { websites, isLoading } = useWebsites();
  const { id } = useParams('id');

  const renderSingleWebsite = (website) => {
    return (
      <div key={website.id} className='flex items-center justify-between on-hover !p-0'>
        <div className='flex items-center gap-1'>
          <Icon
            key={website.id}
            path={website.verified ? mdiCheckCircle : mdiCloseCircle}
            color={website.verified ? Ninja.colors.success : Ninja.colors.warning}
          />
          <Typography.Title level={5} className='!m-0'>
            {website.domain}
          </Typography.Title>
        </div>

        <div className='flex show-on-hover'>
          <Link className='block' to={P.WEBSITES.EDIT(website.id)}>
            <Button
              htmlType='button'
              shape='circle'
              size='small'
              icon={<EditIcon size='1rem' />}
              type='text'
            />
          </Link>
        </div>
      </div>
    );
  };

  const items = useMemo(() => {
    return websites.map((website, i) => {
      const res = {
        key: website.id,
        label: renderSingleWebsite(website),
      };

      if (website.subdomains.length > 0) {
        res.children = website.subdomains.map((subdomain) => {
          return {
            key: subdomain.id,
            label: renderSingleWebsite(subdomain),
          };
        });
      }

      return res;
    });
  }, [websites]);

  if (isLoading) {
    return <Skeleton active />;
  }

  return (
    <Menu
      mode='inline'
      items={items}
      selectedKeys={id ? [id] : []}
      className='website-menu'
    />
  );
};

export default ConnectedWebsitesList;
