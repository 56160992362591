import ninjaApi from 'store/redux/apis/ninja.api';

export const hubspotApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['NetworkAccount'],
  endpoints: (build) => ({
    connectHubSpot: build.mutation({
      query: (data) => ({
        url: '/apps/hubspot/auth/connect',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['NetworkAccount'],
    }),
  }),
});

export const { useConnectHubSpotMutation } = hubspotApi;
