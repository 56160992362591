import React, { useEffect, useState } from 'react';
import { useListShopifyQuery } from 'modules/apps/apps/ShopifyApp/api/shopify.api';
import { Select, Space } from 'antd';
import PropTypes from 'prop-types';

const ShopifySelect = (props) => {
  const { onChange, value = [], selectAll = true } = props;
  const { data = [], isFetching } = useListShopifyQuery();
  const [initialValue, setInitialValue] = useState(value);

  useEffect(() => {
    if (selectAll) {
      if (value.length > 0) {
        setInitialValue(value);
      } else {
        setInitialValue(data?.data?.map((opt) => opt.id));
      }
    }
  }, [value]);

  const handleChange = (newValue) => {
    onChange?.(newValue);
  };

  if (isFetching) {
    return null;
  }

  return (
    <Select
      value={initialValue}
      className='w-full'
      placeholder='Select Shopify'
      onChange={handleChange}
      mode='multiple'
      maxTagCount='responsive'
    >
      {data.data?.map((opt) => (
        <Select.Option value={opt.id} label={opt.name} key={opt.id}>
          <Space>
            <span role='img' aria-label={opt.name}>
              <img src='/icons/integrations/shopify.svg' className='w-5' />
            </span>
            {opt.name}
          </Space>
        </Select.Option>
      ))}
    </Select>
  );
};

export default ShopifySelect;

ShopifySelect.propTypes = {
  onChange: PropTypes.object.isRequired,
  value: PropTypes.array.isRequired,
};
