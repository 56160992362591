import React from 'react';
import { Checkbox, Popconfirm, Spin } from 'antd';
import useAccountConnectionFlow from 'components/AccountConnectionFlow/AccountConnectionFlow.provider';
import { LoadingOutlined } from '@ant-design/icons';

const ActivateAllAccountsCheckbox = (props) => {
  const {
    manage: { connectAllAccounts, disconnectAllAccounts, connectAllLoading },
    accounts: { connectedAccounts },
  } = useAccountConnectionFlow();

  const checked = connectedAccounts.every((account) => account.connected);
  const [open, setOpen] = React.useState(false);

  const onConfirm = (e) => {
    if (checked) {
      disconnectAllAccounts().then(() => {
        setOpen(false);
      });
    } else {
      connectAllAccounts().then(() => {
        setOpen(false);
      });
    }
  };

  return (
    <Popconfirm
      placement='top'
      title={`Are you sure you want to ${
        checked ? 'disconnect' : 'connect'
      } all accounts from MyDataNinja?`}
      description={checked ? 'Account data will be discarded' : ''}
      okText='Yes'
      open={open}
      onCancel={() => setOpen(false)}
      cancelText='No'
      okButtonProps={{ loading: connectAllLoading }}
      onConfirm={onConfirm}
    >
      {connectAllLoading ? (
        <Spin indicator={<LoadingOutlined spin />} size='small' />
      ) : (
        <Checkbox onClick={() => setOpen(true)} loading={connectAllLoading} checked={checked} />
      )}
    </Popconfirm>
  );
};

export default ActivateAllAccountsCheckbox;
