import React from 'react';
import WebsiteLayout from 'modules/website/layouts/Website.layout';
import { Card } from 'antd';
import CreateWebsiteForm from 'modules/website/forms/CreateWebsiteForm';

const WebsiteCreatePage = (props) => {
  return (
    <WebsiteLayout>
      <Card className='w-full' title='Add New Website'>
        <CreateWebsiteForm />
      </Card>
    </WebsiteLayout>
  );
};

WebsiteCreatePage.propTypes = {};

export default WebsiteCreatePage;
