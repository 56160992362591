import React from 'react';
import WebsiteLayout from 'modules/website/layouts/Website.layout';
import { Card } from 'antd';
import EditWebsiteForm from 'modules/website/forms/EditWebsiteForm';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const WebsiteEditPage = (props) => {
  const { id } = useParams();

  return (
    <WebsiteLayout>
      <Card className='w-full' title='Edit website'>
        <EditWebsiteForm id={id} />
      </Card>
    </WebsiteLayout>
  );
};

export default WebsiteEditPage;
