import React, { useEffect, useState } from 'react';
import { Tabs, Flex, Modal, Button, Spin, Input, Typography, notification } from 'antd';
import useQueryParams from 'hooks/useQueryParams';
import { useFlowListQuery } from 'modules/CRMModule/api/crm.api';
import DealStatuses from './DealStatuses';
import Icon from '@mdi/react';
import { mdiPlus, mdiPencil, mdiDelete } from '@mdi/js';
import {
  useCreateFlowMutation,
  useDeleteFlowMutation,
  useUpdateFlowMutation,
} from 'modules/CRMModule/api/crm.api';
import TableIcons from 'components/table-formatters/TableIcons/TableIcons';
import Ninja from '@ninja';

const StatusConfiguration = () => {
  const { setUrlParams, searchParams } = useQueryParams();
  const { flow_id } = searchParams;
  const [value, setValue] = useState(flow_id);
  const { data: source = [], isLoading: isSourceLoading } = useFlowListQuery();
  const [createFlow, { isLoading }] = useCreateFlowMutation();
  const [deleteFlow, { isLoading: deleteLoading }] = useDeleteFlowMutation();
  const [updateFlow, { isLoading: updateLoading }] = useUpdateFlowMutation();
  const [flow, setFlow] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editFlowData, setEditFlowData] = useState(null);

  useEffect(() => {
    if (flow_id) {
      setValue(flow_id);
    }
  }, [flow_id]);

  useEffect(() => {
    if (!isSourceLoading && source?.data && source?.data[0]?.id && !flow_id) {
      setUrlParams({ flow_id: source?.data[0]?.id });
      setValue(flow_id);
    }
  }, [isSourceLoading]);

  const onChange = (key) => {
    setUrlParams({ flow_id: key });
  };

  const handleCreateFlow = () => {
    createFlow({ name: flow })
      .unwrap()
      .then((data) => {
        console.log(data.data.id);
        setUrlParams({ flow_id: data.data.id });
        setValue(data.data.id);
        notification.success({ message: 'Flow created successfully!' });
      })
      .catch(() => {
        notification.error({ message: 'Failed to create flow' });
      });
  };

  const handleDeleteFlow = (id) => {
    Modal.confirm({
      title: 'Are you sure you want to delete this flow?',
      onOk: () => {
        deleteFlow({ id })
          .unwrap()
          .then(() => {
            notification.success({ message: `${editFlowData.name} deleted successfully!` });
          })
          .catch(() => {
            notification.error({ message: `Failed to delete ${editFlowData.name}` });
          });
      },
      onCancel: () => {
        console.log('Delete action canceled');
      },
    });
  };

  const handleEditFlow = (flow) => {
    setEditFlowData(flow);
    setIsEditModalOpen(true);
  };

  const handleUpdateFlow = () => {
    if (!editFlowData?.id) return;
    updateFlow(editFlowData)
      .unwrap()
      .then(() => {
        setIsEditModalOpen(false);
        notification.success({ message: `${editFlowData.name} updated successfully!` });
      })
      .catch(() => {
        notification.error({ message: `Failed to update ${editFlowData.name}` });
      });
  };

  const icons = [
    {
      icon: mdiDelete,
      tooltip: 'Delete',
      size: 0.7,
      loading: deleteLoading,
      onClick: (id) => {
        const flowToDelete = source?.data.find((s) => s.id === id);
        setEditFlowData(flowToDelete);
        handleDeleteFlow(id);
      },
      onlyOnHover: true,
    },
    {
      icon: mdiPencil,
      tooltip: 'Edit',
      size: 0.7,
      onClick: (id) => {
        const flowToEdit = source?.data.find((s) => s.id === id);
        handleEditFlow(flowToEdit);
      },
    },
  ];

  if (isSourceLoading) {
    return <Spin size='large' className='mt-4 m-x-auto w-full' />;
  }

  return (
    <>
      <Tabs
        type='card'
        className='mt-6 text-sm font-normal'
        activeKey={String(value)}
        onChange={onChange}
        items={[
          ...((source?.data &&
            source?.data.map((s) => ({
              label: (
                <Flex
                  className='min-w-24 icons-hover-trigger h-full w-full'
                  justify='space-between'
                  align='center'
                  gap='middle'
                >
                  <Typography className='text-sm'>{s.name}</Typography>
                  <TableIcons
                    color={Ninja.colors.medium}
                    icons={icons.map((icon) => ({
                      ...icon,
                      onClick: icon.onClick ? () => icon.onClick(s.id) : undefined,
                    }))}
                    size={0.5}
                  />
                </Flex>
              ),
              key: String(s.id),
              children: <DealStatuses flow_id={s.id} className='mt-5' />,
            }))) ||
            []),

          {
            label: (
              <Flex align='center' gap='small'>
                <Icon path={mdiPlus} size={0.7} />
               Create Flow
              </Flex>
            ),
            key: 'new_flow',
            children: (
              <Flex className='mt-5' gap='small' align='small'>
                <Input
                  className='max-w-64 w-full'
                  size='medium'
                  placeholder='Enter Flow Name'
                  value={flow}
                  onChange={(e) => setFlow(e.target.value)}
                />
                <Button type='primary' onClick={handleCreateFlow} loading={isLoading}>
                  Create
                </Button>
              </Flex>
            ),
          },
        ]}
      />

      <Modal
        title={`Edit ${editFlowData?.name}`}
        centered
        open={isEditModalOpen}
        onOk={handleUpdateFlow}
        onCancel={() => setIsEditModalOpen(false)}
        confirmLoading={updateLoading}
        okText='Save'
      >
        <Input
          placeholder='Enter New Name'
          value={editFlowData?.name}
          onChange={(e) => setEditFlowData({ ...editFlowData, name: e.target.value })}
        />
      </Modal>
    </>
  );
};

export default StatusConfiguration;
