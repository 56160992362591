import React from 'react';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import useQueryParams from 'hooks/useQueryParams';
import MetaConnectionFlow from 'modules/apps/apps/MetaApp/components/MetaConnectionFlow/MetaConnectionFlow';
import { AccountConnectionFlowContextProvider } from 'components/AccountConnectionFlow/AccountConnectionFlow.provider';

const description = 'Some description';

const MetaAppDrawer = (props) => {
  const { setUrlParams } = useQueryParams();

  return (
    <AutomaticDrawer size='large' title='Connect Meta Account' width='80%' {...props}>
      <AccountConnectionFlowContextProvider>
        <div className='flex flex-col'>
          <MetaConnectionFlow />
        </div>
      </AccountConnectionFlowContextProvider>
    </AutomaticDrawer>
  );
};

MetaAppDrawer.useDrawer = () => {
  const { setUrlParams, removeParams } = useQueryParams();

  const open = () => setUrlParams({ app: 'meta' });
  const close = () => removeParams(['app']);

  return {
    open,
    close,
  };
};

MetaAppDrawer.requiredParams = {
  app: (app) => 'meta' === app,
};

MetaAppDrawer.closeParams = ['connection_batch', 'step'];

export default MetaAppDrawer;
