import React, { useState, useEffect, useCallback } from 'react';
import { Button, Card, Divider, Space, Radio, Popover, Flex } from 'antd';
import { useWidget } from './useWidget';
import { mdiFloppy, mdiFilter, mdiFileTree } from '@mdi/js';
import Icon from '@mdi/react';
import NetworkAccountSelect from 'components/Selects/NetworkAccountSelect/NetworkAccountSelect';
import StatusSelect from 'components/Selects/StatusSelect';
import DateRangePicker from 'components/DateRangePicker';
import ShopifySelect from 'components/Selects/ShopifySelect';
import WoocommerceAccountSelect from 'components/Selects/WoocommerceAccountSelect';
import PropTypes from 'prop-types';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import { useDashboardLayout } from 'store/redux/states/dashboard/dashboard.hooks';
import Ninja from '@ninja';

const WidgetFilter = ({
  index,
  disableDateFilter,
  disableStatusFilter,
  showShopifyFilter = false,
  showWoocommerceFilter = false,
  disableAccountsFilter,
  default_filter,
  disableLevelFilter,
  changeLevel,
  options,
}) => {
  const { widget_key, filters, setFilters, runFilter } = useWidget();
  const { setDate, dates } = useWidgetDate(widget_key);
  const {
    date_from: globalDateFrom,
    date_to: globalDateTo,
    date_range_key: globalKey,
  } = useWidgetDate('global');
  const { config, updateWidget, isLoading, setDashboard } = useDashboardLayout();
  const [tempFilters, setTempFilters] = useState(filters);

  // Sync tempFilters with filters
  useEffect(() => {
    setTempFilters(filters);
  }, [filters]);

  const handleAccountFiltersChange = useCallback((account_ids) => {
    setTempFilters((prev) => ({
      ...prev,
      network_account_ids: account_ids,
    }));
  }, []);

  const handleStatusChange = useCallback((statuses) => {
    setTempFilters((prev) => ({
      ...prev,
      statuses,
    }));
  }, []);

  const handleShopifyFilter = useCallback((val) => {
    setTempFilters((prev) => ({
      ...prev,
      integration_ids: val,
    }));
  }, []);

  const resetFilter = useCallback(() => {
    setTempFilters({ ...default_filter });
    setFilters({ ...default_filter });

    if (default_filter.date_from || default_filter.date_range_key) {
      setDate({
        date_from: default_filter.date_from,
        date_to: default_filter.date_to,
        date_range_key: default_filter.date_range_key,
      });
    } else {
      setDate({
        date_from: globalDateFrom,
        date_to: globalDateTo,
        date_range_key: globalKey,
      });
    }
    runFilter();
  }, [default_filter, setFilters, setDate, runFilter]);

  const filterWidget = useCallback(() => {
    setFilters({ ...tempFilters, ...dates });
    runFilter();
  }, [tempFilters, dates, setFilters, runFilter]);

  const updateDefaultFilter = useCallback(() => {
    runFilter();
    const updatedConfig = [...config];

    const dateRange = dates?.date_range_key
      ? { date_from: null, date_to: null, date_range_key: dates.date_range_key }
      : { ...dates };

    updatedConfig[index] = {
      ...updatedConfig[index],
      defaultFilter: { ...tempFilters, ...dateRange },
    };

    setFilters(tempFilters);
    setDashboard({ config: updatedConfig });
    updateWidget(updatedConfig);
  }, [config, tempFilters, dates, index, setFilters, setDashboard, updateWidget, runFilter]);

  return (
    <Popover
      placement='bottomLeft'
      content={
        <Card size='small' className='w-[400px]'>
          <Space direction='vertical' className='w-full'>
            <div className='grid grid-cols-2 gap-2'>
              {!disableDateFilter && (
                <div className='col-span-2 md:col-span-1'>
                  <DateRangePicker
                    size='small'
                    variant='input'
                    date_key={widget_key}
                    defaultValue={{
                      date_range_key: filters.date_range_key,
                      date_from: filters.date_from,
                      date_to: filters.date_to,
                    }}
                  />
                </div>
              )}
              {!disableStatusFilter && (
                <div className='col-span-2 md:col-span-1 h-full'>
                  <StatusSelect value={tempFilters.statuses} onChange={handleStatusChange} className='h-full' />
                </div>
              )}
            </div>
            {!disableAccountsFilter && (
              <NetworkAccountSelect
                value={tempFilters.network_account_ids}
                onChange={handleAccountFiltersChange}
                maxTagCount={10}
              />
            )}
            {!disableLevelFilter && (
              <div className='grid gap-2 mt-2'>
                <Flex align='center' gap='small'>
                  <Icon path={mdiFileTree} className='text-background-dark' size={0.6} />
                  <span className='!text-sm  text-background-dark'>Change Level</span>
                </Flex>
                <Radio.Group
                  optionType='button'
                  buttonStyle='solid'
                  value={tempFilters.level}
                  onChange={changeLevel}
                  options={options}
                />
              </div>
            )}
            {showShopifyFilter && (
              <ShopifySelect
                onChange={handleShopifyFilter}
                value={tempFilters.integration_ids || null}
              />
            )}
            {showWoocommerceFilter && (
              <WoocommerceAccountSelect
                onChange={handleShopifyFilter}
                value={tempFilters.integration_ids || null}
              />
            )}
            <Divider className='my-2' />
            <div className='flex justify-between'>
              <Button type='dashed' onClick={resetFilter}>
                Reset
              </Button>
              <div className='flex items-center gap-2'>
                <Button onClick={filterWidget} loading={isLoading} className='w-fit' type='primary'>
                  Filter
                </Button>
                <Button
                  loading={isLoading}
                  icon={<Icon path={mdiFloppy} size={0.6} />}
                  onClick={updateDefaultFilter}
                  className='w-fit'
                >
                  Save
                </Button>
              </div>
            </div>
          </Space>
        </Card>
      }
    >
      <Button
        type='text'
        size='medium'
        icon={<Icon path={mdiFilter} color={Ninja.colors.darkText} />}
      />
    </Popover>
  );
};

WidgetFilter.propTypes = {
  disableDateFilter: PropTypes.bool,
  disableStatusFilter: PropTypes.bool,
  disableAccountsFilter: PropTypes.bool,
  index: PropTypes.number.isRequired,
  showShopifyFilter: PropTypes.bool,
  showWoocommerceFilter: PropTypes.bool,
  disableLevelFilter: PropTypes.bool,
  default_filter: PropTypes.object,
  changeLevel: PropTypes.func,
  options: PropTypes.array,
};

export default WidgetFilter;
