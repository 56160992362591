import React from 'react';
import { Button } from 'antd';
import NetworkIcon from 'components/NetworkIcon';
import useAccountConnectionFlow from 'components/AccountConnectionFlow/AccountConnectionFlow.provider';
import { snackbar } from 'components/notifications/notifications';
import useGoogleLogin from 'modules/network-accounts/hooks/useGoogleLogin';
import { useConnectGoogleAdsMutation } from 'modules/apps/apps/GoogleAdsApp/api/google-ads.api';

const ConnectGoogleAdsStep = () => {
  const flow = useAccountConnectionFlow();
  const { loading: authLoading, openLogin: openGoogleAdsLoginPopup } = useGoogleLogin();
  const [connectGoogleAdsAccountsRequest, { isLoading: connectLoading }] =
    useConnectGoogleAdsMutation();

  /**
   * Responsible for connecting GoogleAds accounts to MyDataNinja
   * Gets the SDK response from GoogleAds and then connects the accounts to MyDataNinja
   */
  const connectGoogleAds = async () => {
    const sdkResponse = await snackbar.promise(() => openGoogleAdsLoginPopup(), {
      loadingMessage: 'Waiting for GoogleAds Login...',
      successMessage: 'Successfully Logged in',
      errorMessage: 'Error while login to GoogleAds',
    });

    if (!sdkResponse.success) {
      throw new Error('Error while login to GoogleAds');
    }

    const apiResponse = await snackbar.promise(
      () => connectGoogleAdsAccountsRequest(sdkResponse).unwrap(),
      {
        loadingMessage: 'Connecting to MyDataNinja...',
        successMessage: 'Successfully Connected',
        errorMessage: 'Error while connecting GoogleAds to MyDataNinja',
      }
    );

    if (!apiResponse.connection_batch) {
      throw new Error('Error while connecting accounts to mydataninja');
    }

    flow.setConnectionBatch(apiResponse.connection_batch);
    flow.changeStep('choose_accounts');
  };

  return (
    <div className='w-full flex-center pt-8'>
      <Button
        onClick={connectGoogleAds}
        type='dashed'
        size='large'
        loading={authLoading || connectLoading}
        icon={<NetworkIcon network='google' />}
      >
        {flow.loading
          ? 'Please Finish connection or refresh'
          : 'Click Here To Connect Your GoogleAds Ad Accounts'}
      </Button>
    </div>
  );
};

export default ConnectGoogleAdsStep;
