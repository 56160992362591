import React from 'react';
import { Button } from 'antd';
import NetworkIcon from 'components/NetworkIcon';
import { snackbar } from 'components/notifications/notifications';
import useBingLogin from 'modules/network-accounts/hooks/useBingLogin';
import { useConnectBingAdsMutation } from 'modules/apps/apps/BingAdsApp/api/bing-ads.api';
import useAccountConnectionFlow from 'components/AccountConnectionFlow/AccountConnectionFlow.provider';

const ConnectBingAdsStep = (props) => {
  const { onError } = props;
  const flow = useAccountConnectionFlow();
  const { loading: authLoading, openLogin: openBingAdsLoginPopup } = useBingLogin();
  const [connectBingAdsAccountsRequest, { isLoading: isConnectLoading }] =
    useConnectBingAdsMutation();

  const connectBingAds = async () => {
    const sdkResponse = await snackbar.promise(() => openBingAdsLoginPopup(), {
      loadingMessage: 'Waiting for BingAds Login...',
      successMessage: 'Successfully Logged in',
      errorMessage: 'Error while login to BingAds',
    });

    if (!sdkResponse.success) {
      throw new Error('Error while login to BingAds');
    }

    /**
     * @var {ConnectBingAdsResponse} sdkResponse
     */
    const apiResponse = await snackbar.promise(
      () => connectBingAdsAccountsRequest(sdkResponse).unwrap(),
      {
        loadingMessage: 'Connecting to MyDataNinja...',
        successMessage: 'Successfully Connected',
        errorMessage: 'Error while connecting BingAds to MyDataNinja',
      }
    );

    if (!apiResponse.connection_batch) {
      throw new Error('Error while connecting accounts to mydataninja');
    }

    flow.setConnectionBatch(apiResponse.connection_batch);

    return apiResponse.connection_batch;
  };

  const isLoading = isConnectLoading || authLoading;

  return (
    <div className='w-full flex-center pt-8'>
      <Button
        onClick={connectBingAds}
        type='dashed'
        size='large'
        loading={isLoading}
        icon={<NetworkIcon network='bing' />}
      >
        {isLoading
          ? 'Please Finish connection or refresh'
          : 'Click Here To Connect Your BingAds Ad Accounts'}
      </Button>
    </div>
  );
};

ConnectBingAdsStep.propTypes = {};

export default ConnectBingAdsStep;
