import { Button, Collapse, Divider, Form, Input, Popconfirm, Typography } from 'antd';
import CreateIcon from 'components/icons/CreateIcon';
import DeleteIcon from 'components/icons/DeleteIcon';
import TrashIcon from 'components/icons/TrashIcon';
import { snackbar } from 'components/notifications/notifications';
import { useDeleteWebsiteMutation } from 'modules/DomainModule/api/website.api';
import WebsiteVerifier from 'modules/website/forms/WebsiteForm/WebsiteVerifier';
import React from 'react';

const _initialValues = {
  id: null,
  domain: '',
  subdomains: [],
  aliases: [],
  is_subdomain: false,
  is_alias: false,
};

const WebsiteForm = (props) => {
  const { initialValues = _initialValues, editing = false, loading } = props;
  const [form] = Form.useForm();
  const [deleteWebsiteQuery, { isLoading: isDeleteLoading }] = useDeleteWebsiteMutation();

  const deleteWebsite = () => {
    return deleteWebsiteQuery(initialValues.id)
      .unwrap()
      .then(() => {
        snackbar.success('Website deleted successfully');
      });
  };

  return (
    <div className='flex flex-col gap-4'>
      <div className='grid gap-4'>
        <Form onFinish={props.onSubmit} form={form} layout='vertical' initialValues={initialValues} className='grid gap-2 p-4'>
          <Form.Item
            label={
              <Typography.Title level={5} className='!mb-0'>
                Enter the website domain you want to connect:
              </Typography.Title>
            }
            name='domain'
            rules={[{ required: true }]}
          >
            <Input size='large' placeholder='e.g. example.com' />
          </Form.Item>

          {!initialValues.is_subdomain && !initialValues.is_alias && (
            <Collapse
              style={{ backgroundColor: 'red !important' }}
              className='font-normal text-sm !bg-background-light rounded-md mb-2'
              bordered={false}
              items={[
                {
                  key: '1',
                  label: 'Website Subdomains',
                  children: (
                    <Form.List name='subdomains' rules={[{ required: true }]}>
                      {(fields, { add, remove }, { errors }) => {
                        return (
                          <div className='pl-4'>
                            {fields.map((field, index) => (
                              <Form.Item
                                required={true}
                                label={'Subdomain ' + (index + 1)}
                                key={field.key}
                              >
                                <Form.Item name={[field.name, 'id']} hidden />
                                <div className='flex-center gap-2'>
                                  <Form.Item
                                    {...field}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                      {
                                        required: true,
                                        whitespace: true,
                                        message: 'Please input subdomain or delete this field.',
                                      },
                                    ]}
                                    name={[field.name, 'domain']}
                                    noStyle
                                  >
                                    <Input
                                      size='large'
                                      placeholder={
                                        form.getFieldValue('domain')
                                          ? `e.g. prefix.${form.getFieldValue('domain')}`
                                          : 'e.g. sub1.example.com'
                                      }
                                    />
                                  </Form.Item>
                                  <Button
                                    onClick={() => remove(field.name)}
                                    shape='circle'
                                    type='default'
                                    size='large'
                                    icon={<DeleteIcon />}
                                  />
                                </div>
                              </Form.Item>
                            ))}
                            <Form.Item className='mt-4'>
                              <Button type='dashed' onClick={() => add()} icon={<CreateIcon />}>
                                Add Subdomain
                              </Button>
                            </Form.Item>
                          </div>
                        );
                      }}
                    </Form.List>
                  ),
                },
              ]}
            />
          )}

          {!initialValues.is_subdomain && !initialValues.is_alias && (
            <Collapse
              className='font-normal text-sm !bg-background-light rounded-md '
              bordered={false}
              items={[
                {
                  key: '1',
                  label: 'Website Aliases',
                  children: (
                    <Form.List name='aliases' rules={[{ required: true }]}>
                      {(fields, { add, remove }, { errors }) => {
                        return (
                          <div className='pl-4'>
                            {fields.map((field, index) => (
                              <Form.Item
                                required={true}
                                label={'Alias ' + (index + 1)}
                                key={field.key}
                              >
                                <Form.Item name={[field.name, 'id']} hidden />
                                <div className='flex-center gap-2'>
                                  <Form.Item
                                    {...field}
                                    validateTrigger={['onChange', 'onBlur']}
                                    rules={[
                                      {
                                        required: true,
                                        whitespace: true,
                                        message: 'Please input alias or delete this field.',
                                      },
                                    ]}
                                    name={[field.name, 'domain']}
                                    noStyle
                                  >
                                    <Input size='large' placeholder='alias.com' />
                                  </Form.Item>
                                  <Button
                                    onClick={() => remove(field.name)}
                                    shape='circle'
                                    type='default'
                                    size='large'
                                    icon={<DeleteIcon />}
                                  />
                                </div>
                              </Form.Item>
                            ))}
                            <Form.Item className='mt-4'>
                              <Button type='dashed' onClick={() => add()} icon={<CreateIcon />}>
                                Add Alias
                              </Button>
                            </Form.Item>
                          </div>
                        );
                      }}
                    </Form.List>
                  ),
                },
              ]}
            />
          )}

          {editing && <WebsiteVerifier id={initialValues.id} />}
          <Divider />
          <div className={`w-full flex ${editing ? 'justify-between' : 'justify-center'} my-2`}>
            {editing && (
              <Popconfirm
                title='Delete the website'
                onConfirm={async () => deleteWebsite()}
                description='Are you sure to delete this website?'
              >
                <Button
                  htmlType='button'
                  loading={isDeleteLoading}
                  danger
                  icon={<TrashIcon size='1rem' />}
                  type='text'
                >
                  Delete
                </Button>
              </Popconfirm>
            )}
            <Button loading={loading} type='primary' size='large' htmlType='submit'>
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default WebsiteForm;
