import React, { useState } from 'react';
import useQueryParams from 'hooks/useQueryParams';
import {
  useConnectAccountMutation,
  useDisconnectAccountMutation,
  useGetAccountsByBatchQuery,
  useGetBatchConnectedAccountsQuery,
  useSetupAccountMutation,
  useSkipAccountSetupMutation,
} from 'store/redux/apis/network-accounts/network-accounts.api';

const AccountConnectionFlowContext = React.createContext();

export const AccountConnectionFlowContextProvider = (props) => {
  // Get initial values from URL
  const {
    setUrlParams,
    removeParams,
    searchParams: { connection_batch = null, step = 'connect' },
  } = useQueryParams();

  // Connection batch used for identifying connected accounts
  const [connectionBatch, _setConnectionBatch] = useState(connection_batch);

  // Get connected accounts by the batch
  const { data: connectedAccounts = [], isLoading: isAccountsLoading } = useGetAccountsByBatchQuery(
    {
      batch: connectionBatch,
    },
    {
      skip: !connectionBatch,
    }
  );
  const { data: activatedAccounts = [] } = useGetBatchConnectedAccountsQuery(
    {
      batch: connectionBatch,
    },
    {
      pollingInterval: 2000,
      skip: !connectionBatch,
    }
  );
  const [connectQuery, { isLoading: isConnectLoading }] = useConnectAccountMutation();
  const [disconnectQuery, { isLoading: isDisconnectLoading }] = useDisconnectAccountMutation();
  const [setupAccountQuery, { isLoading: isSetupLoading }] = useSetupAccountMutation();
  const [skipAccountSetupQuery, { isLoading: isSkipLoading }] = useSkipAccountSetupMutation();

  const [connectAllLoading, setConnectAllLoading] = useState(false);

  const changeStep = (newStep) => {
    setUrlParams({ step: newStep });
  };

  const setConnectionBatch = (batch) => {
    _setConnectionBatch(batch);
    if (batch) {
      setUrlParams({ connection_batch: batch });
    } else {
      removeParams(['connection_batch']);
    }
  };

  /**
   * Get accounts that are connected by the batch
   * @returns {Array<NetworkAccount>}
   */
  const getConnectedAccounts = () => {
    return connectedAccounts?.data || [];
  };

  /**
   * Get accounts that are connected by the batch
   * @returns {Array<NetworkAccount>}
   */
  const getActivatedAccounts = () => {
    return activatedAccounts?.data || [];
  };

  /**
   * Connects account to the platform
   * @param {Number} accountId
   * @returns {Promise}
   */
  const connectAccount = (accountId) => {
    return connectQuery({
      batch: connectionBatch,
      account_id: accountId,
    }).unwrap();
  };

  /**
   * Connects account to the platform
   * @param {Number} accountId
   * @returns {Promise}
   */
  const disconnectAccount = (accountId) => {
    return disconnectQuery({
      batch: connectionBatch,
      account_id: accountId,
    }).unwrap();
  };

  /**
   * Requests setup of the accounts
   * @param {Number} accountId
   * @param {Object} setup The setup steps to complete
   * @returns {Promise}
   */
  const requestSetup = (accountId, setup = {}) => {
    return setupAccountQuery({
      batch: connectionBatch,
      account_id: accountId,
      setup: setup,
    }).unwrap();
  };

  /**
   * Skips setup for the account
   * @param {Number} accountId
   * @returns {Promise}
   */
  const skipSetup = (accountId) => {
    return skipAccountSetupQuery({
      batch: connectionBatch,
      account_id: accountId,
    }).unwrap();
  };

  /**
   * Connect all accounts
   * @returns {Promise}
   */
  const connectAllAccounts = () => {
    setConnectAllLoading(true);

    return connectAccount(getConnectedAccounts().map((account) => account.id))
      .then(() => {
        setConnectAllLoading(false);
      })
      .catch(() => {
        setConnectAllLoading(false);
      });
  };

  /**
   * DisConnect all accounts
   * @returns {Promise}
   */
  const disconnectAllAccounts = () => {
    setConnectAllLoading(true);

    return disconnectAccount(getConnectedAccounts().map((account) => account.id))
      .then(() => {
        setConnectAllLoading(false);
      })
      .catch(() => {
        setConnectAllLoading(false);
      });
  };

  return (
    <AccountConnectionFlowContext.Provider
      value={{
        step: step,
        connectionBatch,
        changeStep,
        setConnectionBatch,
        manage: {
          isLoading: isConnectLoading || isDisconnectLoading || isSetupLoading || isSkipLoading,
          isConnectLoading,
          isDisconnectLoading,
          isSetupLoading,
          isSkipLoading,
          connectAllLoading,
          connectAccount,
          connectAllAccounts,
          disconnectAllAccounts,
          disconnectAccount,
          requestSetup,
          skipSetup,
        },
        accounts: {
          isLoading: isAccountsLoading,
          connectedAccounts: getConnectedAccounts(),
          activatedAccounts: getActivatedAccounts(),
        },
      }}
    >
      {props.children}
    </AccountConnectionFlowContext.Provider>
  );
};

const useAccountConnectionFlow = () => React.useContext(AccountConnectionFlowContext);

export default useAccountConnectionFlow;

/**
 * @typedef {{
 *  access_token: string,
 *  success: boolean,
 * }} GoogleAdsJsSdkResponse
 */

/**
 * @typedef {{
 *  success: boolean,
 *  connection_batch: string
 * }} ConnectGoogleAdsResponse
 */
