import * as amp from '@amplitude/analytics-browser';
import * as sessionReplay from '@amplitude/session-replay-browser';

export const init = () => {
  return {};
};

export const amplitude = () => {
  return window.amplitude;
};
