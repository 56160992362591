import React from 'react';
import { Steps } from 'antd';
import ConnectGoogleAdsStep from 'modules/apps/apps/GoogleAdsApp/components/GoogleAdsConnectionFlow/ConnectGoogleAdsStep';
import ActivateGoogleAdsAccountsStep from 'modules/apps/apps/GoogleAdsApp/components/GoogleAdsConnectionFlow/ActivateGoogleAdsAccountsStep';
import SetUpGoogleAdsAccountsStep from 'modules/apps/apps/GoogleAdsApp/components/GoogleAdsConnectionFlow/SetUpGoogleAdsAccountsStep';
import useAccountConnectionFlow from 'components/AccountConnectionFlow/AccountConnectionFlow.provider';
import CompleteAccountConnectionFlowStep from 'components/AccountConnectionFlow/CompleteAccountConnectionFlowStep';

const GoogleAdsConnectionFlow = (props) => {
  const flow = useAccountConnectionFlow();
  const { step } = flow;

  const steps = [
    {
      index: 0,
      name: 'connect',
      title: 'Connect to MyDataNinja',
      description: 'Give access to your Google Ads accounts',
      content: <ConnectGoogleAdsStep />,
    },
    {
      index: 1,
      name: 'choose_accounts',
      title: 'Choose Accounts',
      description: 'Which you want to work with',
      content: <ActivateGoogleAdsAccountsStep />,
    },
    {
      index: 2,
      name: 'set_up',
      title: 'Set Up Accounts',
      description: 'Configure tracking and other settings',
      content: <SetUpGoogleAdsAccountsStep />,
    },
    {
      index: 3,
      name: 'complete',
      title: 'Finish',
      description: 'Ready to Go!',
      content: <CompleteAccountConnectionFlowStep />,
    },
  ];

  const findStep = (stepName) => {
    return steps.find(({ name }) => name === stepName);
  };

  const currentStep = findStep(step);

  return (
    <div className='flex flex-col'>
      <div className='w-full'>
        <Steps
          responsive={true}
          current={currentStep?.index || 0}
          items={steps.map(({ title, description }) => ({ title, description }))}
        />
      </div>
      <div className='w-full mt-4'>{currentStep?.content || 'Error loading content'}</div>
    </div>
  );
};

GoogleAdsConnectionFlow.propTypes = {};

export default GoogleAdsConnectionFlow;
