import { Checkbox, Divider, Radio, Segmented, Select, Space, Tooltip } from 'antd';
import NetworkIcon from 'components/NetworkIcon/NetworkIcon';
import React, { Fragment, useState, useEffect } from 'react';

const NetworkSwitch = ({
  selectedNetwork,
  handleNetworksChange,
  disableNetworksSelector,
  allSelected,
  selectAllChanged,
  disableSelectAll,
  options,
}) => {
  const [network, setNetwork] = useState(selectedNetwork);
  useEffect(() => {
    setNetwork(selectedNetwork);
  }, [selectedNetwork]);

  return (
    <Fragment>
      <div className='flex items-center justify-between'>
        {!disableSelectAll && (
          <Tooltip title={allSelected ? 'Clear All' : `Select All ${options.length}`}>
            <Checkbox className='ml-3' checked={allSelected} onChange={selectAllChanged} />
          </Tooltip>
        )}
        {!disableNetworksSelector && (
          <Segmented
            value={network}
            onChange={handleNetworksChange}
            options={[
              {
                label: <span className='text-xs'>All</span>,
                value: 'all',
              },
              {
                label: <NetworkIcon network='facebook' size={15} />,
                value: 'facebook',
              },
              {
                label: <NetworkIcon network='google' size={15} />,
                value: 'google',
              },
              {
                label: <NetworkIcon network='bing' size={15} />,
                value: 'bing',
              },
              {
                label: <NetworkIcon network='tiktok' size={15} />,
                value: 'tiktok',
              },
            ]}
          />
        )}
      </div>
      <Divider className='my-2' />
    </Fragment>
  );
};

export default NetworkSwitch;
