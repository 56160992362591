import React, { useEffect, useState } from 'react';
import { Checkbox, Typography } from 'antd';
import clsx from 'clsx';

const HubSpotAccountSetup = ({ onChange }) => {
  const [create, setCreate] = useState({
    pixel: true,
    auto_tracking: true,
  });

  const handleChange = (e) => {
    setCreate((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  useEffect(() => {
    onChange && onChange(create);
  }, [create]);

  return (
    <div>
      <Typography.Paragraph>
        MyDataNinja recomends some settings in your account work properly and well optimized, you
        can choose which steps to follow to optimize your account for MyDataNinja
      </Typography.Paragraph>
      <div className='flex flex-col'>
        <div className='flex gap-2 items-center'>
          <Checkbox onChange={handleChange} name='pixel' checked={create.pixel} />
          <Typography.Paragraph
            className={clsx('!mb-0 mt-1', {
              'line-through': !create.pixel,
            })}
          >
            Enable auto tracking for all Campaigns, Adsets and Ads.
            <Typography.Link href='https://mydataninja.com/blog/' target='_blank'>
              {' '}
              Learn More
            </Typography.Link>
          </Typography.Paragraph>
        </div>
        <div className='flex gap-2 items-center'>
          <Checkbox onChange={handleChange} name='auto_tracking' checked={create.auto_tracking} />
          <Typography.Paragraph
            className={clsx('!mb-0 mt-1', {
              'line-through': !create.auto_tracking,
            })}
          >
            Create default pixel and standard conversion events (If not already created).
            <Typography.Link href='https://mydataninja.com/blog/' target='_blank'>
              {' '}
              Learn More
            </Typography.Link>
          </Typography.Paragraph>
        </div>
      </div>
    </div>
  );
};

HubSpotAccountSetup.propTypes = {};

export default HubSpotAccountSetup;
