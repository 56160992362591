import { useListWebsitesQuery } from 'modules/DomainModule/api/website.api';

export default function useWebsites() {
  const { data, isLoading, isError } = useListWebsitesQuery();

  return {
    websites: data?.data || [],
    isLoading,
    isError,
  };
}
