import ninjaApi from 'store/redux/apis/ninja.api';

export const websitesApi = ninjaApi.injectEndpoints({
  tagTypes: ['Websites', 'DefaultWorkspace'],
  overrideExisting: true,
  endpoints: (builder) => ({
    /**
     * List all workspace websites
     */
    listWebsites: builder.query({
      query: (body) => ({
        url: '/websites',
        body,
      }),
      providesTags: ['Websites'],
    }),
    /**
     * Get Single Postback
     */
    getWebsite: builder.query({
      query: (id) => ({
        url: `/websites/${id}`,
        method: 'GET',
      }),
      providesTags: ['Websites'],
    }),
    /**
     * Create new postback
     */
    createWebsite: builder.mutation({
      query: (body) => ({
        url: '/websites',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Websites'],
    }),
    /**
     * Update existing website
     */
    updateWebsite: builder.mutation({
      query: (body) => ({
        url: `/websites/${body.id}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Websites'],
    }),
    /**
     * Delete Website
     */
    deleteWebsite: builder.mutation({
      query: (id) => ({
        url: `/websites/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Websites'],
    }),

    websiteAliases: builder.mutation({
      query: (body) => ({
        url: '/workspace/websites/update_alias',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Websites'],
    }),
  }),
});

export const {
  useListWebsitesQuery,
  useGetWebsiteQuery,
  useCreateWebsiteMutation,
  useUpdateWebsiteMutation,
  useDeleteWebsiteMutation,
  useWebsiteAliasesMutation,
} = websitesApi;
