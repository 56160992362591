import Module from 'modules/Module';
import P from 'router/paths';
import WebsiteIndexPage from 'modules/website/pages/Website.index';
import WebsiteCreatePage from 'modules/website/pages/WebsiteCreatePage';
import WebsiteEditPage from 'modules/website/pages/WebsiteEditPage';

const WebsiteModule = (props) => {
  const module = new Module({
    routes: [
      {
        path: P.WEBSITES.INDEX,
        component: WebsiteIndexPage,
        exact: true,
      },
      {
        path: P.WEBSITES.CREATE,
        component: WebsiteCreatePage,
        exact: true,
      },
      {
        path: P.WEBSITES.EDIT(':id'),
        component: WebsiteEditPage,
        exact: true,
      },
      // { path: P.DOMAIN.CREATE, component: CreateDomainPage, exact: true },
      // { path: P.DOMAIN.STATUS, component: DomainStatusPage, exact: true },
      // { path: P.DOMAIN.EDIT(':id'), component: EditDomainPage, exact: true },
    ],
  });

  return module.render();
};

WebsiteModule.propTypes = {};

export default WebsiteModule;
