import ninjaApi from 'store/redux/apis/ninja.api';

/**
 * Shopify connection api
 */
export const shopifyApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['Shopify'],
  endpoints: (build) => ({
    listShopify: build.query({
      query: (data) => ({
        url: '/apps/shopify/list',
        method: 'POST',
        data,
      }),
      providesTags: ['Shopify'],
    }),
    requestShopifyAuthorization: build.mutation({
      query: (data) => ({
        url: '/shopify/integrate',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['Shopify'],
    }),

    shopifyWidgets: build.query({
      query: (data) => ({
        url: '/dashboard/widgets/shopify',
        method: 'POST',
        data: data,
      }),
    }),
  }),
});

export const {
  useListShopifyQuery,
  useRequestShopifyAuthorizationMutation,
  useShopifyWidgetsQuery,
} = shopifyApi;
