import React from 'react';
import { Table } from 'antd';
import NetworkAccountFormatter from 'components/table-formatters/network-account';
import AccountActivationCheckbox from 'modules/AccountsModule/components/AccountActivationCheckbox';
import useAccountConnectionFlow from 'components/AccountConnectionFlow/AccountConnectionFlow.provider';
import ConnectionFlowFooterButtons from 'components/AccountConnectionFlow/ConnectionFlowFooterButtons';
import ActivateAllAccountsCheckbox from 'components/AccountConnectionFlow/ActivateAllAccountsCheckbox';

const ChooseAccountsStep = () => {
  const { accounts, changeStep } = useAccountConnectionFlow();

  const columns = [
    {
      title: (
        <div>
          <ActivateAllAccountsCheckbox />
        </div>
      ),
      width: 50,
      key: 'status',
      render: (_, account) => <AccountActivationCheckbox account={account} />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      width: 200,
      key: 'name',
      render: (_, account) => <NetworkAccountFormatter copy={false} row={account} />,
    },
    {
      title: 'Account ID',
      dataIndex: 'account_id',
      key: 'account_Id',
    },
    {
      title: 'Timezone',
      dataIndex: 'timezone',
      key: 'timezone',
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
  ];

  const isAtLeastOneSelected = accounts.connectedAccounts.some((account) => account.connected);

  return (
    <div className='flex flex-col gap-4'>
      <div>
        <Table
          loading={accounts.isLoading}
          pagination={false}
          size='small'
          columns={columns}
          dataSource={accounts.connectedAccounts}
        />
      </div>

      <div className='w-full flex justify-between'>
        <ConnectionFlowFooterButtons
          nextTooltip={!isAtLeastOneSelected ? 'Please select at least one account' : null}
          prevProps={{ isLoading: accounts.isLoading, onClick: () => changeStep('connect') }}
          nextProps={{
            onClick: () => changeStep('set_up'),
            disabled: isAtLeastOneSelected ? false : true,
          }}
        />
      </div>
    </div>
  );
};

ChooseAccountsStep.propTypes = {};

export default ChooseAccountsStep;
