import ninjaApi from 'store/redux/apis';

export const reportApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: [],
  endpoints: (builder) => ({
    getReportInfo: builder.mutation({
      query: (body) => ({
        url: '/reporting/get-info',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useGetReportInfoMutation } = ninjaApi;
