import React from 'react';
import { Button } from 'antd';
import NetworkIcon from 'components/NetworkIcon';
import { snackbar } from 'components/notifications/notifications';
import useTiktokLogin from 'modules/network-accounts/hooks/useTiktokLogin';
import useAccountConnectionFlow from 'components/AccountConnectionFlow/AccountConnectionFlow.provider';

const ConnectTiktokStep = (props) => {
  const flow = useAccountConnectionFlow();
  const { loading: authLoading, openLogin: openTiktokLoginPopup } = useTiktokLogin();

  const connectTiktok = async () => {
    const loginWindow = await snackbar.promise(() => openTiktokLoginPopup(), {
      loadingMessage: 'Waiting for Tiktok Login...',
      successMessage: 'Successfully Logged in',
      errorMessage: 'Error while login to Tiktok',
    });

    return await new Promise((res, rej) => {
      const onConnectionBatch = (batch) => {
        flow.setConnectionBatch(batch);
        res(batch);
        loginWindow.close();
        snackbar.success('Successfully connected Tiktok accounts');
        flow.changeStep('choose_accounts');
      };

      window.addEventListener('message', (event) => {
        console.log(event, 'event');
        try {
          if (event.data?.message !== 'mydataninja_tiktok_connected') {
            return;
          }

          if (!event.data.connection_batch) {
            throw new Error('Error while getting connection batch: ' + JSON.stringify(event.data));
          }

          onConnectionBatch(event.data.connection_batch);
        } catch (err) {
          console.log(err);
          snackbar.error('Error while connecting tiktok accounts, concact support');
        }
      });
    });
  };

  return (
    <div className='w-full flex-center pt-8'>
      <Button
        onClick={connectTiktok}
        type='dashed'
        size='large'
        loading={authLoading}
        icon={<NetworkIcon network='tiktok' />}
      >
        {authLoading
          ? 'Please Finish connection or refresh'
          : 'Click Here To Connect Your Tiktok Ad Accounts'}
      </Button>
    </div>
  );
};

ConnectTiktokStep.propTypes = {};

export default ConnectTiktokStep;
