import { Alert as AntAlert } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { AlertConfig } from './const';
import Icon from '@mdi/react';
import { mdiCloseThick } from '@mdi/js';

const Alert = (props) => {
  const { type, message, ...rest } = props;
  const alertConfig = AlertConfig[type];

  return (
    <AntAlert
      type={type}
      message={message && <span style={{ color: alertConfig.color }}>{alertConfig.title}</span>}
      icon={<img src={alertConfig.icon} alt={`${type} icon`} />}
      closable={{
        'aria-label': 'close',
        closeIcon: <Icon path={mdiCloseThick} color={alertConfig.color} />,
      }}
      {...rest}
    />
  );
};

Alert.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
};

export default Alert;
