import React, { Fragment, useMemo } from 'react';
import useNetworkAccounts from 'store/redux/hooks/useNetworkAccounts';
import HeaderButton from '../HeaderButton/HeaderButton';
import { Menu, Popover, Typography, Flex } from 'antd';
import Icon from '@mdi/react';
import { mdiFileTree } from '@mdi/js';
import NetworkAccountSingleItem from './NetworkAccountSingleItem';

export default function AccountChooser() {
  const { accounts, isLoading: isAccountsLoading } = useNetworkAccounts({});
  const networks = ['facebook', 'google', 'bing', 'tiktok'];

  const activeCounts = useMemo(() => {
    return networks.reduce((acc, network) => {
      acc[network] = accounts.filter((acc) => acc.network === network && acc.active).length;
      return acc;
    }, {});
  }, [accounts]);

  const menu = useMemo(() => {
    const _menu = [];
    /** @type {Array<Network>} */

    networks.forEach((network) => {
      const icon = (
        <img src={`/icons/networks/${network}.svg`} alt={network} className='w-3 ml-px' />
      );
      const networkAccounts = accounts.filter((acc) => acc.network === network);

      if (networkAccounts.length > 0) {
        _menu.push({
          key: network,
          icon: icon,
          label: (
            <Flex align='center' justify='space-between' gap='small'>
              <Typography className='text-sm'>
                {network === 'facebook'
                  ? 'Meta'
                  : network.charAt(0).toUpperCase() + network.slice(1)}
              </Typography>
              <Typography className='text-xs text-gray'>{activeCounts[network]} Active</Typography>
            </Flex>
          ),
          children: networkAccounts
            .filter((acc) => acc.is_manager || network)
            .map((acc) => ({
              key: acc.id,
              icon: icon,
              label: <NetworkAccountSingleItem account={acc} />,
            })),
        });
      }
    });

    return _menu;
  }, [accounts]);

  return (
    <Fragment>
      <HeaderButton style={{ border: 0, display: 'grid', padding: '10px' }} color='darkText'>
        <Popover
          overlayClassName='[&_.ant-popover-inner]:!p-0 w-[300px]'
          zIndex={9999999999}
          content={
            <Menu
              inlineIndent={12}
              triggerSubMenuAction='click'
              onClick={() => {}}
              mode='inline'
              className='
                [&_ul.ant-menu-sub]:max-h-[400px] 
                [&_ul.ant-menu-sub]:overflow-y-scroll
              '
              items={menu}
            />
          }
          trigger='click'
          className='grid h-full justify-between '
        >
          <div className='leading-3 text-left space-x-2.5 flex items-center'>
            <Icon path={mdiFileTree} size={0.7} />
            <span>Choose Accounts</span>
          </div>
          <div className='flex flex-wrap items-center gap-2'>
            {networks.map((network) => (
              <div key={network} className='flex gap-2 items-center pr-1 border-r'>
                <img src={`/icons/networks/${network}.svg`} alt={network} className='w-3' />
              </div>
            ))}
          </div>
        </Popover>
      </HeaderButton>
    </Fragment>
  );
}
