import React, { useEffect, useState } from 'react';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import useQueryParams from 'hooks/useQueryParams';
import { useUpdateFormFieldMutation } from 'store/redux/apis/crm.api';
import ninjaApi from 'store/redux/apis';
import FieldForm from 'modules/CRMModule/pages/CRMDataninja/components/FieldForm';
import { snackbar } from 'components/notifications/notifications';

const UpdateLeadFieldDrawer = (props) => {
  const { searchParams, removeParams } = useQueryParams();
  const [updateFormField, { isLoading }] = useUpdateFormFieldMutation();

  const [getGetFormQuery] = ninjaApi.useLazyGetFormQuery();
  const { field_id, form_id } = searchParams;
  const [formData, setFormData] = useState({});

  useEffect(() => {
    getGetFormQuery({ id: form_id })
      .unwrap()
      .then((data) => {
        if (field_id) {
          const fieldData = data.fields.find((field) => field.id == field_id);
          setFormData(fieldData);
        }
      });
  }, [field_id, form_id]);

  const updateField = async (data) => {
    updateFormField({
      id: data.id,
      fields: Object.entries(data)
        .filter(([, value]) => value !== undefined)
        .map(([key, value]) => ({ field: key, value: value })),
    })
      .unwrap()
      .then(() => {
        snackbar.success(`${data.name} updated successfully.`);
        removeParams(['edit', 'field_id', 'form_id']);
      });
  };

  return (
    <AutomaticDrawer
      size='large'
      title={formData?.name ? `Edit ${formData.name} field` : 'Edit Field'}
      {...props}
    >
      <FieldForm editingField={formData} onChange={updateField} isLoading={isLoading} />
    </AutomaticDrawer>
  );
};

UpdateLeadFieldDrawer.requiredParams = {
  edit: true,
  field_id: (p) => parseInt(p) > 0,
  form_id: (p) => parseInt(p) > 0,
};

export default UpdateLeadFieldDrawer;
