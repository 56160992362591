import { currency_options } from '@ninja/consts';

export default function getFormattedValue(value, currency = null) {
  let currency_config = null;
  if (currency) {
    currency_config = currency_options[currency];
  } else {
    currency_config = window.currency_config;
  }

  if (!currency_config) return value;

  if (value === null || value === undefined) return `0${currency_config.symbol}`;
  if (currency_config.sign_location === 'pre') {
    if (value < 0) {
      return `-${Math.abs(value)}${currency_config.symbol}`;
    } else {
      return `${Math.abs(value)}${currency_config.symbol}`;
    }
  } else {
    return `${value}${currency_config.symbol}`;
  }
}
