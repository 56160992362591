import React from 'react';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import useQueryParams from 'hooks/useQueryParams';
import BingAdsConnectionFlow from 'modules/apps/apps/BingAdsApp/components/BingAdsConnectionFlow/BingAdsConnectionFlow';
import { AccountConnectionFlowContextProvider } from 'components/AccountConnectionFlow/AccountConnectionFlow.provider';

const BingAdsAppDrawer = (props) => {
  return (
    <AutomaticDrawer size='large' title='Connect BingAds Account' width='80%' {...props}>
      <AccountConnectionFlowContextProvider>
        <div className='flex flex-col'>
          <BingAdsConnectionFlow />
        </div>
      </AccountConnectionFlowContextProvider>
    </AutomaticDrawer>
  );
};

BingAdsAppDrawer.useDrawer = () => {
  const { setUrlParams, removeParams } = useQueryParams();

  const open = () => setUrlParams({ app: 'bing-ads' });
  const close = () => removeParams(['app']);

  return {
    open,
    close,
  };
};

BingAdsAppDrawer.requiredParams = {
  app: (app) => 'bing-ads' === app,
};

BingAdsAppDrawer.closeParams = ['connection_batch', 'step'];

export default BingAdsAppDrawer;
