import { CssBaseline, LinearProgress } from '@material-ui/core';
import '@ninja';
import App from './App';
import MuiThemeWrapper from 'common/@mui/theme';
import React, { Suspense } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { store } from 'store/redux/store';
import { ConfigProvider } from 'antd';
import './styles/index.css';
import { UrlContextProvider } from 'hooks/useQueryParams';
import { antdOverride } from './ant-design';

const Bootstrap = () => {
  return (
    <BrowserRouter>
      <MuiThemeWrapper>
        <ReduxProvider store={store}>
          <CssBaseline />
          <UrlContextProvider>
            <Suspense fallback={<LinearProgress />}>
              <ConfigProvider theme={antdOverride}>
                <App />
              </ConfigProvider>
            </Suspense>
          </UrlContextProvider>
        </ReduxProvider>
      </MuiThemeWrapper>
    </BrowserRouter>
  );
};

export default Bootstrap;
