import React from 'react';
import { Steps } from 'antd';
import ConnectHubSpotStep from 'modules/apps/apps/HubSpotApp/components/HubSpotConnectionFlow/ConnectHubSpotStep';
import ActivateHubSpotAccountsStep from 'modules/apps/apps/HubSpotApp/components/HubSpotConnectionFlow/ActivateHubSpotAccountsStep';
import SetUpHubSpotAccountsStep from 'modules/apps/apps/HubSpotApp/components/HubSpotConnectionFlow/SetUpHubSpotAccountsStep';
import CompleteAccountConnectionFlowStep from 'components/AccountConnectionFlow/CompleteAccountConnectionFlowStep';
import useAccountConnectionFlow from 'components/AccountConnectionFlow/AccountConnectionFlow.provider';

const HubSpotConnectionFlow = () => {
  const flow = useAccountConnectionFlow();
  const { step } = flow;

  const steps = [
    {
      index: 0,
      name: 'connect',
      title: 'Connect to MyDataNinja',
      description: 'Give access to your HubSpot accounts',
      content: <ConnectHubSpotStep />,
    },
    {
      index: 1,
      name: 'choose_accounts',
      title: 'Choose Accounts',
      description: 'Which you want to work with',
      content: <ActivateHubSpotAccountsStep />,
    },
    {
      index: 2,
      name: 'set_up',
      title: 'Set Up Accounts',
      description: 'Configure tracking and other settings',
      content: <SetUpHubSpotAccountsStep />,
    },
    {
      index: 3,
      name: 'complete',
      title: 'Finish',
      description: 'Ready to Go!',
      content: <CompleteAccountConnectionFlowStep />,
    },
  ];

  const findStep = (stepName) => {
    return steps.find(({ name }) => name === stepName);
  };

  const currentStep = findStep(step);

  return (
    <div className='flex flex-col'>
      <div className='w-full'>
        <Steps
          responsive={true}
          current={currentStep?.index || 0}
          items={steps.map(({ title, description }) => ({ title, description }))}
        />
      </div>
      <div className='w-full mt-4'>{currentStep?.content || 'Error loading content'}</div>
    </div>
  );
};

HubSpotConnectionFlow.propTypes = {};

export default HubSpotConnectionFlow;
