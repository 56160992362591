import clsx from 'clsx';
import React from 'react';
import PropTypes from 'prop-types';

const RoiFormatter = ({ row: { roi }, className = 'px-[7px] py-[5px] rounded-[12px] text-xs' }) => {
  if (!roi) return null;
  return (
    <span
      className={clsx(
        {
          'bg-error text-white': roi <= 0,
          'bg-warning text-white': roi > 0 && roi < 30,
          'bg-success text-white': roi > 30,
        },
        className
      )}
    >
      {roi.toFixed(2)}%
    </span>
  );
};

export default RoiFormatter;

RoiFormatter.propTypes = {
  row: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};
