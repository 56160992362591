import React, { useState } from 'react';
import { Checkbox, Popconfirm, Spin, Tooltip } from 'antd';
import { snackbar } from 'components/notifications/notifications';
import useAccountConnectionFlow from 'components/AccountConnectionFlow/AccountConnectionFlow.provider';
import { LoadingOutlined } from '@ant-design/icons';

const AccountActivationCheckbox = ({ account }) => {
  const flow = useAccountConnectionFlow();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const showPopconfirm = () => {
    setOpen(true);
  };

  const stopLoading = () => {
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const connectAccount = () => {
    setIsLoading(true);
    flow.manage
      .connectAccount(account.id)
      .then((res) => {
        snackbar.success(`Account "${account.name}" successfully connected`);
      })
      .catch((err) => {
        snackbar.error('Error while connecting account');
      })
      .finally(() => {
        setOpen(false);
        stopLoading();
      });
  };

  const disconnectAccount = () => {
    setIsLoading(true);

    flow.manage
      .disconnectAccount(account.id)
      .then((res) => {
        snackbar.success(`Account "${account.name}" successfully disconnected`);
      })
      .catch((err) => {
        snackbar.error('Error while disconnecting account');
      })
      .finally(() => {
        setOpen(false);
        stopLoading();
      });
  };

  if (isLoading) {
    return <Spin indicator={<LoadingOutlined spin />} size='small' />;
  }

  if (account.connected) {
    return (
      <Popconfirm
        placement='top'
        title='Are you sure you want to disconnect account from MyDataNinja?'
        description='Account data will be discarded'
        okText='Yes'
        open={open}
        onCancel={() => setOpen(false)}
        cancelText='No'
        okButtonProps={{ loading: isLoading }}
        onConfirm={() => disconnectAccount(false)}
      >
        <Checkbox onClick={showPopconfirm} loading={isLoading} checked={account.connected} />
      </Popconfirm>
    );
  }

  return (
    <Tooltip title='Check to connect to MyDataNinja'>
      <Checkbox loading={isLoading} checked={account.connected} onChange={connectAccount} />
    </Tooltip>
  );
};

AccountActivationCheckbox.propTypes = {};

export default AccountActivationCheckbox;
