import React from 'react';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import useQueryParams from 'hooks/useQueryParams';
import GoogleAdsConnectionFlow from 'modules/apps/apps/GoogleAdsApp/components/GoogleAdsConnectionFlow/GoogleAdsConnectionFlow';
import { AccountConnectionFlowContextProvider } from 'components/AccountConnectionFlow/AccountConnectionFlow.provider';

const GoogleAdsAppDrawer = (props) => {
  return (
    <AutomaticDrawer size='large' title='Connect GoogleAds Account' width='80%' {...props}>
      <AccountConnectionFlowContextProvider>
        <div className='flex flex-col'>
          <GoogleAdsConnectionFlow />
        </div>
      </AccountConnectionFlowContextProvider>
    </AutomaticDrawer>
  );
};

GoogleAdsAppDrawer.useDrawer = () => {
  const { setUrlParams, removeParams } = useQueryParams();

  const open = () => setUrlParams({ app: 'google-ads' });
  const close = () => removeParams(['app', ...GoogleAdsAppDrawer.closeParams]);

  return {
    open,
    close,
  };
};

GoogleAdsAppDrawer.requiredParams = {
  app: (app) => 'google-ads' === app,
};

GoogleAdsAppDrawer.closeParams = ['connection_batch', 'step'];

export default GoogleAdsAppDrawer;
