import React from 'react';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import useQueryParams from 'hooks/useQueryParams';
import { useCreateFormFieldMutation } from 'store/redux/apis/crm.api';
import FieldForm from 'modules/CRMModule/pages/CRMDataninja/components/FieldForm';
import { snackbar } from 'components/notifications/notifications';

const CreateLeadFieldDrawer = (props) => {
  const { searchParams, removeParams } = useQueryParams();
  const [createFormField, { isLoading }] = useCreateFormFieldMutation();
  const { form_id } = searchParams;


  const createField = async (data) => {
    console.log(data)
    await createFormField({
      crm_form_id: form_id,
      ...data,
    }).unwrap().then((data) => {
      snackbar.success(`${data.name} created successfully.`);
      removeParams(['create_field', 'form_id']);
    });
  };

  return (
    <AutomaticDrawer size='large' title='Add a new field' {...props}>
      <FieldForm onChange={createField} isLoading={isLoading} />
    </AutomaticDrawer>
  );
};

CreateLeadFieldDrawer.requiredParams = {
  create_field: true,
  form_id: (p) => parseInt(p) > 0,
};

export default CreateLeadFieldDrawer;
