import initGoogleSdk from '@lib/google/initGoogleSdk';
import { googleApiKey } from '@ninja/consts';
import { snackbar } from 'components/notifications/notifications';
import { useState } from 'react';
import { useConnectGoogleMutation } from 'store/redux/apis/network-accounts/network-accounts.api';
import useWorkspace from 'store/redux/hooks/useWorkspace';

const googleScopes = {
  adwords: 'https://www.googleapis.com/auth/adwords',
  'userinfo.email': 'https://www.googleapis.com/auth/userinfo.email',
  'userinfo.profile': 'https://www.googleapis.com/auth/userinfo.profile',
};

const useGoogleLogin = (props) => {
  const {
    workspace: { is_demo },
  } = useWorkspace();

  const [loading, setLoading] = useState(false);

  const loadSDK = async () => {
    await initGoogleSdk();
  };

  const startGoogleLoginDialog = async () => {
    if (is_demo) {
      window.alert("You can't connect accounts on demo account");
    }

    setLoading(true);
    // Load sdk
    await loadSDK();

    const authInstance = window.gapi.auth2.getAuthInstance();
    if (!authInstance) return Promise.reject('No Google SDK Found');

    return await authInstance
      .grantOfflineAccess({
        apiKey: googleApiKey,
        scope: Object.values(googleScopes).join(' '),
        access_type: 'offline',
        prompt: 'consent',
      })
      .then((data) => {
        if (!data.code) {
          throw new Error('No Google SDK Error');
        }

        const user = authInstance.currentUser.get();
        if (!user) {
          throw new Error('No Google SDK User');
        }

        const tokenData = user.getAuthResponse(true);
        tokenData.code = data.code;
        tokenData.success = true;
        setLoading(false);
        return tokenData;
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  return {
    loading: loading,
    loadSDK: loadSDK,
    openLogin: startGoogleLoginDialog,
  };
};

export default useGoogleLogin;
