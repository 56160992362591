import React from 'react';
import { Table } from 'antd';
import NetworkAccountFormatter from 'components/table-formatters/network-account';
import AccountSetupButton from 'components/AccountConnectionFlow/AccountSetupButton';
import useAccountConnectionFlow from 'components/AccountConnectionFlow/AccountConnectionFlow.provider';
import ConnectionFlowFooterButtons from 'components/AccountConnectionFlow/ConnectionFlowFooterButtons';

const SetupAccountsStep = (props) => {
  const flow = useAccountConnectionFlow();

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 200,
      key: 'name',
      render: (_, account) => <NetworkAccountFormatter copy={false} row={account} />,
    },
    {
      title: 'Setup',
      width: 150,
      render: (_, account) => <AccountSetupButton account={account} />,
    },
    {
      title: 'Campaigns',
      dataIndex: 'campaigns_count',
      key: 'campaigns_count',
    },
    {
      title: 'Adsets',
      dataIndex: 'adsets_count',
      key: 'adsets_count',
    },
    {
      title: 'Ads',
      dataIndex: 'ads_count',
      key: 'ads_count',
    },
    {
      title: 'Conversions',
      dataIndex: 'conversions_count',
      key: 'conversions_count',
    },
  ];

  const nextAvialable = () => {
    return flow.accounts.activatedAccounts.every((account) => {
      return account.data?.setup_status !== null;
    });
  };

  return (
    <div className='flex flex-col gap-4'>
      <div>
        <Table
          pagination={false}
          size='small'
          columns={columns}
          dataSource={flow.accounts.activatedAccounts}
        />
      </div>
      <div className='w-full flex justify-between'>
        <ConnectionFlowFooterButtons
          prevProps={{
            onClick: () => flow.changeStep('choose_accounts'),
          }}
          nextProps={{
            onClick: () => flow.changeStep('complete'),
            disabled: !nextAvialable(),
          }}
          nextTooltip={
            nextAvialable()
              ? null
              : 'Please proceed by setting up tracking for each chosen account individually or skipping the setup for each one separately.'
          }
        />
      </div>
    </div>
  );
};

export default SetupAccountsStep;
