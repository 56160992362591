import React, { useEffect } from 'react';
import { Button, Modal, Spin } from 'antd';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import ConnectedShopifyStores from 'modules/apps/apps/ShopifyApp/tables/ConnectedShopifyStores';
import useQueryParams from 'hooks/useQueryParams';
import useWorkspace from 'store/redux/hooks/useWorkspace';
import { useRequestShopifyAuthorizationMutation } from '../api/shopify.api';
import { AlertType } from 'components/Dialogs/const';

const ShopifyAppDrawer = (props) => {
  const { searchParams, removeParams } = useQueryParams();
  const { workspace } = useWorkspace();
  const connecting = Boolean(searchParams.connecting);
  const [requestAuthorizationQuery, isLoading] = useRequestShopifyAuthorizationMutation();

  const startConnectingShopify = () => {
    window.open(
      `https://plugins-sh.ninjans.com/?workspace_id=${workspace.id}`,
      '_blank',
      'width=600,height=700'
    );
  };

  useEffect(() => {
    if (searchParams.connecting) {
      requestAuthorizationQuery({
        store_domain: searchParams.shop,
        form_name: searchParams.form_name,
        store_name: searchParams.store_name,
        has_app: searchParams.has_app,
      })
        .unwrap()
        .then((data) => {
          console.log(data);
          const url = data.url;
          window.location.replace(url);
        })
        .catch((err) => {
          removeParams(['connecting', 'shop', 'form_name', 'store_name', 'has_app']);
          window.alert(err.data.message);
        });
    }
  }, []);

  return (
    <AutomaticDrawer size='large' title='Shopify' {...props}>
      <div className='flex flex-col gap-4'>
        <Modal
          closable={false}
          centered={true}
          className='flex flex-center'
          title='Your shop is being connected to mydataninja'
          footer={false}
          // If passed in url
          open={connecting}
        >
          <br /> You will be redirected to the shopify page to authorize the connection
          <div className='mt-4 text-center'>
            <Spin size='large' />
          </div>
        </Modal>

        <div className='py-4'>
          <Button type='primary' onClick={startConnectingShopify} isLoading={isLoading}>
            Connect New Store
          </Button>
        </div>

        <div>
          <ConnectedShopifyStores />
        </div>
      </div>
    </AutomaticDrawer>
  );
};

ShopifyAppDrawer.requiredParams = {
  app: (app) => 'shopify' === app,
};

export default ShopifyAppDrawer;
