import { useState } from 'react';
import useWorkspace from 'store/redux/hooks/useWorkspace';
import { useConnectHubSpotMutation } from 'modules/apps/apps/HubSpotApp/api/hubspot.api';

const useHubSpotLogin = (props) => {
  const [connectHubSpotAccountsRequest, { isLoading: isConnectLoading }] =
    useConnectHubSpotMutation();
  const {
    workspace: { is_demo },
  } = useWorkspace();

  /**
   * Start hubspot login process
   * @returns {Promise<Window>}
   */
  const startHubSpotLoginDialog = async () => {
    if (is_demo) {
      window.alert("You can't connect accounts on demo account");
    }

    return await connectHubSpotAccountsRequest()
      .unwrap()
      .then((data) => {
        console.log('data', data);

        if (!data.redirect_url) {
          throw new Error('Error while getting redirect url');
        }

        return data.redirect_url;
      })
      .then((redirect_url) => {
        const w = window.open(redirect_url, 'HubSpot Login MyDataNinja', 'width=600,height=600');
        return w;
      });
  };

  return {
    loading: isConnectLoading,
    openLogin: startHubSpotLoginDialog,
    connectLoading: isConnectLoading,
  };
};

export default useHubSpotLogin;
