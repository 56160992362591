import React, {
  createContext,
  Fragment,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
  useMemo,
} from 'react';
import NinjaTableFooter from './NinjaTableFooter';
import NinjaTableGrid from './NinjaTableGrid';
import NinjaTableWrapper from './NinjaTableWrapper';
import useColumns from './useColumns';
import ninjaApi from 'store/redux/apis/ninja.api';
import _isEqual from 'lodash/isEqual';
import clsx from 'clsx';
import { useWidgetDate } from 'store/redux/states/dates/dates.hooks';
import { useWidget } from 'components/Widget/useWidget';
import { useLocation } from 'react-router-dom';
import FileDownload from 'js-file-download';
import axios from 'axios';

export const NinjaTableContext = createContext({});

export const NinjaTable = (props) => {
  const {
    page = 1,
    per_page = 10,
    dataProvider,
    queryParams = {},
    FilterByDates = true,
    /**
     * Parameter
     * @type {Object} savedFiltersParams
     */
    savedFiltersParams,
    /**
     * Props that is send to table card wrapper
     * @type {Object} cardProps
     */
    cardProps = {},
    /**
     * If true, columns can be sorted horizontally
     */
    sortableColumns = true,
    sortable,
    getComparator,
    header,
    datepicker,
    /**
     * @todo move to table id or one unique identifier
     */
    customizationId,
    exportData,
    customize,
    onCustomizationUpdate,
    customizationEdit,
    showFilter,
    /**
     * @todo should be passed from columns, or column should be disabled
     */
    removeColumns,
    rowHeight,
    /* table props */
    columns: allColumns = [],
    tableProps = {
      template: 'outerreporting',
      dynamicHeight: true,
    },
    footerProps = {
      showPagination: true,
      showPerPage: true,
      totalDataNum: false,
    },
    disableFooter = false,
    maxHeight = null,

    // datepicker props
    showExpandDays = false,
    date_key,

    /* table wrapper props, [table can be without wrapper or filters] */
    title,
    icon,
    selectRow,

    /* table filter props */
    filterComponent: FilterComponent = Fragment,
    filterProps = {},
    selectorComponent: SelectorComponent = Fragment,
    selectorProps = {},
    enableFilters,
    id,

    /* Other layout components */
    prependComponent,
    tablePrependComponent,
    wrapperPrependComponent,

    containerClassName,
    widget_key,
    defaultFilters = {},
  } = props;

  /* Table State */

  // Saved filters
  const [savedFilters, setSavedFilters] = useState([]);
  const [activeFilters, setActiveFilters] = useState({});

  const [selectedRows, setSelectedRows] = useState(new Set());
  const [statuses, setStatuses] = useState();
  const [localFilterText, setLocalFilterText] = useState('');
  const [extraQueryParams, setExtraQueryParams] = useState(queryParams);
  const [columns, setColumns] = useState(allColumns);
  const [filters, _setFilters] = useState(defaultFilters);
  const [sortColumns, onSortColumnsChange] = useState([]);
  const [pagination, setPagination] = useState({
    page: page,
    per_page: per_page,
    total_pages: 1,
  });

  const { dates: dates_redux } = useWidgetDate();
  const [dates, setDates] = useState(FilterByDates && dates_redux);

  useEffect(() => {
    if (FilterByDates) {
      setDates(dates_redux);
    } else {
      setDates(null);
    }
  }, [dates_redux, FilterByDates]);

  const location = useLocation();
  const qParams = new URLSearchParams(location.search);

  const columnProps = useColumns(columns, {
    sortable: sortableColumns,
    id,
  });

  const [refetch, result] = (
    ninjaApi.endpoints[dataProvider] ? ninjaApi.endpoints[dataProvider] : ninjaApi.endpoints.fake
  ).useLazyQuery();

  const _data = Array.isArray(result.data) ? result.data : result.data?.data || [];

  /**
   * Load widget data
   */
  const { widget_filters, runFilterTimestamp } = useWidget(widget_key);

  /**
   * Fetch Data
   * @returns {void}
   */
  const fetchData = () => {
    const data = {
      filters: filters,
      perPage: per_page,
      sort: sortColumns,
      ...dates,
      ...extraQueryParams,
      ...pagination,
      ...queryParams,
      ...widget_filters,
    };
    refetch(data)
      ?.unwrap()
      .then((res) => {
        if (res.export_file_url && extraQueryParams.export) {
          axios({
            url: res.export_file_url,
            method: 'GET',
            responseType: 'blob',
          }).then((response) => {
            FileDownload(response.data, 'reporting.xlsx');
          });
        }
      });
  };

  useEffect(() => {
    fetchData();
  }, [
    filters,
    statuses,
    pagination.page,
    pagination.per_page,
    dates,
    sortColumns,
    extraQueryParams,
    runFilterTimestamp,
  ]);

  useEffect(() => {
    setColumns(allColumns);
  }, [allColumns]);

  useLayoutEffect(() => {
    const pagination = result.data?.pagination;
    if (!pagination) {
      setPagination({
        page: page,
        per_page: per_page,
        total_pages: 1,
      });
    } else {
      setPagination({
        total_pages: Math.ceil(pagination.total / pagination.per_page),
        ...pagination,
      });
    }
  }, [result.data?.pagination]);

  useEffect(() => {
    const newQueryParams = { ...extraQueryParams, ...queryParams };
    if (_isEqual(newQueryParams, extraQueryParams)) {
      return;
    }

    setExtraQueryParams(newQueryParams);
  }, [queryParams, extraQueryParams]);

  const resultData = _data || [];
  const resultDataFiltered = localFilterText
    ? resultData.filter((d) =>
        Object.values(d).some((v) => (v?.includes ? v.includes(localFilterText) : false))
      )
    : resultData;

  const onExport = () => {
    setExtraQueryParams({
      export: true,
    });

    setTimeout(() => {
      setExtraQueryParams({
        export: false,
      });
    }, 1000);
  };

  const setFilters = (val) => {
    _setFilters((state) => {
      return { ...state, ...val };
    });
  };

  const isLoading = result.isFetching;

  const filterCount = (filters) => {
    let count = 0;

    Object.keys(filters).forEach((key) => {
      const value = filters[key];

      if (value !== null && value !== undefined && value.length !== 0) {
        if (key === 'date_from' || key === 'date_to') {
          count = 1;
        } else if (key === 'registered_at_from' || key === 'registered_at_to') {
          count = 1;
        } else if (key === 'created_at_from' || key === 'created_at_to') {
          count = 1;
        } else if (typeof value === 'object') {
          const nestedCount = Object.entries(value).filter(
            ([nestedKey, nestedValue]) =>
              nestedKey !== 'type' &&
              nestedValue !== null &&
              nestedValue !== undefined &&
              nestedValue !== ''
          ).length;
          if (nestedCount > 0) {
            count++;
          }
        } else if (value !== '') {
          count++;
        }
      }
    });

    return count;
  };
  const sortedRows = useMemo(() => {
    if (sortColumns.length === 0) {
      return resultDataFiltered || [];
    }
    if (typeof getComparator !== 'function' || typeof comparator !== 'function') {
      return resultDataFiltered || [];
    }
    return [...resultDataFiltered].sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = getComparator(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === 'ASC' ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [resultDataFiltered, sortColumns]);

  return (
    <NinjaTableContext.Provider
      value={{
        filters,
        setFilters,
        filterCount,
        pagination,
        setPagination,
        setStatuses,
        setSelectedRows,
        extraQueryParams,
        setExtraQueryParams,
        allColumns,
        setColumns,
        setLocalFilterText,
        isLoading: isLoading,
        totalRows: result?.data?.totals,
        savedFilters,
        setSavedFilters,
        activeFilters,
        setActiveFilters,
        savedFiltersParams,
        onExport,
        customizationId,
        customizationEdit,
        customize,
        onCustomizationUpdate,
        removeColumns,
      }}
    >
      {prependComponent ? prependComponent : null}
      <div className={clsx('flex flex-col w-full', containerClassName)}>
        {enableFilters && (
          <div className='col-span-12'>
            <FilterComponent {...filterProps} />
          </div>
        )}
        <div className='sm:col-span-12'>
          {wrapperPrependComponent && wrapperPrependComponent}
          <NinjaTableWrapper
            title={title}
            icon={icon}
            header={header}
            datepicker={datepicker}
            resultData={resultData}
            customizationId={customizationId}
            exportData={exportData}
            customize={customize}
            showFilter={showFilter}
            filterComponent={<FilterComponent {...filterProps} />}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            onCustomizationUpdate={onCustomizationUpdate}
            customizationEdit={customizationEdit}
            removeColumns={removeColumns}
            datePickerProps={{
              onChange: setDates,
              value: dates_redux,
              changeGlobal: false,
              showExpandDays: showExpandDays,
              date_key: date_key,
            }}
            id={id}
            {...cardProps}
          >
            {tablePrependComponent && tablePrependComponent}
            {selectRow && (
              <SelectorComponent
                pagination={pagination}
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
                setStatuses={setStatuses}
                resultData={resultData}
                filters={filters}
                {...selectorProps}
              />
            )}
            <NinjaTableGrid
              loading={isLoading}
              summaryRows={result.data?.totals || []}
              resultData={resultData}
              rows={sortedRows}
              sortColumns={sortColumns}
              onSortColumnsChange={onSortColumnsChange}
              wrapper={columnProps.DraggableComponent}
              {...columnProps}
              selectedRows={selectedRows}
              onSelectedRowsChange={setSelectedRows}
              maxHeight={maxHeight}
              rowHeight={rowHeight}
              defaultColumnOptions={{
                sortable: sortable,
              }}
              {...tableProps}
            />
            {!disableFooter && <NinjaTableFooter {...footerProps} />}
          </NinjaTableWrapper>
        </div>
      </div>
    </NinjaTableContext.Provider>
  );
};

export default NinjaTable;
