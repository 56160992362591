import { notification } from 'components/notifications/notifications';
import {
  useConfirmEmailMutation,
  useSendMailConfirmationMutation,
} from 'modules/setup/api/email-confirmation.api';
import moment from 'moment/moment';
import useAuth from 'store/redux/hooks/useAuth';
import useWorkspace from 'store/redux/hooks/useWorkspace';
import { ninjaEvents } from 'tracking/ninjaEvents';

const SEND_AGAIN_TIMEOUT_IN_SECONDS = 120;

/**
 * @returns {EmailConfirmHook}
 */
function useEmailConfirm() {
  const { user, isLoading: isUserLoading } = useAuth();
  const { workspace } = useWorkspace();

  const [sendMailConfirmationQuery, { isLoading: isSendLoading }] =
    useSendMailConfirmationMutation();
  const [confirmEmailQuery, { isLoading: isConfirmLoading, isError: isConfirmationError }] =
    useConfirmEmailMutation();

  const sendConfirmEmail = () => {
    return sendMailConfirmationQuery()
      .unwrap()
      .then(() => {
        notification.success('Confirmation email sent');
      })
      .catch(() => {
        notification.error('Error sending confirmation email');
      });
  };

  const confirmEmailByHash = (hash) => {
    return confirmEmailQuery({
      hash: hash,
    })
      .unwrap()
      .then(() => {
        ninjaEvents.emit('email_confirmed', workspace);
        notification.success('Email Successfully Confirmed');
      })
      .catch(() => {
        notification.error('Error confirming email');
      });
  };

  return {
    isLoading: isUserLoading || isSendLoading || isConfirmLoading,
    isUserLoading,
    isSendLoading,
    isConfirmLoading,
    isConfirmationError,
    isConfirmed: Boolean(user?.email_confirmed),
    confirmationSent: Boolean(user?.confirmation_sent_at),
    confirmationSentAt: user?.confirmation_sent_at,
    sendAgainCooldownInSeconds: SEND_AGAIN_TIMEOUT_IN_SECONDS,
    sendConfirm: sendConfirmEmail,
    confirmEmailByHash: confirmEmailByHash,
  };
}

window.moment = moment;

export default useEmailConfirm;

/**
 * @typedef {Object} EmailConfirmHook
 * @property {Boolean} isConfirmed
 * @property {Boolean} confirmationSent
 */
