import React, { useEffect, useState } from 'react';
import { Checkbox, Typography } from 'antd';
import clsx from 'clsx';

const GoogleAdsAccountSetup = ({ onChange }) => {
  const [create, setCreate] = useState({
    track_account: true,
    track_campaigns: true,
    track_ads: true,
    create_conversions: true,
    create_purchase_conversion: true,
    create_lead_conversion: true,
  });

  const handleChange = (e) => {
    if (e.target.name === 'create_conversions') {
      setCreate((prev) => ({
        ...prev,
        create_conversions: e.target.checked,
        create_purchase_conversion: e.target.checked,
        create_lead_conversion: e.target.checked,
      }));
    }

    setCreate((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  useEffect(() => {
    setCreate((prev) => ({
      ...prev,
      create_conversions: prev.create_purchase_conversion || prev.create_lead_conversion,
    }));
  }, [create.create_purchase_conversion, create.create_lead_conversion]);

  useEffect(() => {
    onChange && onChange(create);
  }, [create]);

  return (
    <div>
      <Typography.Paragraph>
        MyDataNinja recomends some settings in your account properly and well optimized, you can
        choose which steps to follow to optimize your account for MyDataNinja
      </Typography.Paragraph>
      <div className='flex flex-col'>
        <div className='flex gap-2 items-center'>
          <Checkbox onChange={handleChange} name='track_account' checked={create.track_account} />
          <Typography.Paragraph
            className={clsx('!mb-0 mt-1', {
              'line-through': !create.track_account,
            })}
          >
            Enable auto tracking for Ad Accounts
            {/* <Typography.Link href='https://mydataninja.com/blog/' target='_blank'>
              {' '}
              Learn More
            </Typography.Link> */}
          </Typography.Paragraph>
        </div>
        <div className='flex gap-2 items-center'>
          <Checkbox
            onChange={handleChange}
            name='track_campaigns'
            checked={create.track_campaigns}
          />
          <Typography.Paragraph
            className={clsx('!mb-0 mt-1', {
              'line-through': !create.track_campaigns,
            })}
          >
            Enable auto tracking for Campaigns
            {/* <Typography.Link href='https://mydataninja.com/blog/' target='_blank'>
              {' '}
              Learn More
            </Typography.Link> */}
          </Typography.Paragraph>
        </div>
        <div className='flex gap-2 items-center'>
          <Checkbox onChange={handleChange} name='track_ads' checked={create.track_ads} />
          <Typography.Paragraph
            className={clsx('!mb-0 mt-1', {
              'line-through': !create.track_ads,
            })}
          >
            Enable auto tracking for Ads
            {/* <Typography.Link href='https://mydataninja.com/blog/' target='_blank'>
              {' '}
              Learn More
            </Typography.Link> */}
          </Typography.Paragraph>
        </div>
        <div className='flex gap-2 items-center'>
          <Checkbox
            onChange={handleChange}
            name='create_conversions'
            checked={create.create_conversions}
          />
          <Typography.Paragraph
            className={clsx('!mb-0 mt-1', {
              'line-through': !create.create_conversions,
            })}
          >
            Create default create_conversions for MyDataNinja
            {/* <Typography.Link href='https://mydataninja.com/blog/' target='_blank'>
              {' '}
              Learn More
            </Typography.Link> */}
          </Typography.Paragraph>
        </div>
        <div className='flex gap-2 items-center pl-8'>
          <Checkbox
            onChange={handleChange}
            name='create_purchase_conversion'
            checked={create.create_purchase_conversion}
          />
          <Typography.Paragraph
            className={clsx('!mb-0 mt-1', {
              'line-through': !create.create_purchase_conversion,
            })}
          >
            Create "MyDataNinja Purchase" conversion
            {/* <Typography.Link href='https://mydataninja.com/blog/' target='_blank'>
              {' '}
              Learn More
            </Typography.Link> */}
          </Typography.Paragraph>
        </div>
        <div className='flex gap-2 items-center pl-8'>
          <Checkbox
            onChange={handleChange}
            name='create_lead_conversion'
            checked={create.create_lead_conversion}
          />
          <Typography.Paragraph
            className={clsx('!mb-0 mt-1', {
              'line-through': !create.create_lead_conversion,
            })}
          >
            Create "MyDataNinja Lead" conversion
            {/* <Typography.Link href='https://mydataninja.com/blog/' target='_blank'>
              {' '}
              Learn More
            </Typography.Link> */}
          </Typography.Paragraph>
        </div>
      </div>
    </div>
  );
};

export default GoogleAdsAccountSetup;
