import React, { Fragment } from 'react';
import { Button, Tooltip } from 'antd';
import Icon from '@mdi/react';
import { mdiArrowLeft, mdiArrowRight } from '@mdi/js';

const ConnectionFlowFooterButtons = (props) => {
  const {
    nextProps = {},
    prevProps = {},
    nextText = 'Next',
    prevText = 'Previous',
    nextTooltip = null,
    prevTooltip = null,
  } = props;

  return (
    <Fragment>
      <Tooltip title={prevTooltip || 'Go to previous step'}>
        <Button type='dashed' icon={<Icon path={mdiArrowLeft} />} {...prevProps}>
          {prevText}
        </Button>
      </Tooltip>
      <Tooltip title={nextTooltip || 'Go to next step'}>
        <Button
          type='primary'
          iconPosition='end'
          icon={<Icon path={mdiArrowRight} />}
          {...nextProps}
        >
          {nextText}
        </Button>
      </Tooltip>
    </Fragment>
  );
};

ConnectionFlowFooterButtons.propTypes = {};

export default ConnectionFlowFooterButtons;
