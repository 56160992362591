import { Button, Result } from 'antd';
import useAccountConnectionFlow from 'components/AccountConnectionFlow/AccountConnectionFlow.provider';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import P from 'router/paths';

const CompleteAccountConnectionFlowStep = () => {
  const flow = useAccountConnectionFlow();

  const history = useHistory();
  const activatedAccountsCount = flow.accounts.activatedAccounts?.length;

  return (
    <Result
      status='success'
      title='Successfully Connected Accounts'
      subTitle={`You have successfully connected ${activatedAccountsCount} accounts. It may take couple minutes to sync all data`}
      extra={[
        <Button
          type='primary'
          onClick={() => {
            history.push(P.DASHBOARD.INDEX);
          }}
          key='dashboard'
        >
          Go To Dashboard
        </Button>,
        <Button
          key='connect'
          onClick={() => {
            flow.setConnectionBatch(null);
            flow.changeStep('connect');
          }}
        >
          Connect More Accounts
        </Button>,
      ]}
    />
  );
};

export default CompleteAccountConnectionFlowStep;
