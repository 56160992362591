import { Card } from 'antd';
import useQueryParams from 'hooks/useQueryParams';
import React from 'react';
import PropTypes from 'prop-types';
import { apps } from 'modules/apps/apps.config';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const AppCard = ({ app }) => {
  const { setUrlParams } = useQueryParams();
  const history = useHistory();

  if (typeof app === 'string') {
    if (typeof apps[app] === 'undefined') {
      return 'Invalid App';
    }
    app = apps[app];
  }

  const onCardClick = () => {
    if (app.url) {
      history.push(app.url);
    } else {
      setUrlParams({ app: app.app });
    }
  };

  return (
    <Card
      className='h-full'
      size='small'
      onClick={onCardClick}
      hoverable
      cover={
        <img src={app.image} className='w-1/2 py-2 h-[150px] px-[20%] m-auto object-contain' />
      }
      bordered={true}
    >
      <Card.Meta title={app.name} description={app.description} />
    </Card>
  );
};

AppCard.propTypes = {
  app: PropTypes.object.isRequired,
};

export default AppCard;
