import React, { useEffect, useState } from 'react';
import { Button, Input, Result, Spin, Typography } from 'antd';
import useWebsite from 'modules/website/hooks/useWebsite';
import TrashIcon from 'components/icons/TrashIcon';
import UrlHelper from 'common/utils/url-helper';

const WebsiteVerifier = ({ id }) => {
  const { website, isLoading, refetch } = useWebsite(id);
  const [checkInterval, setCheckInterval] = useState(null);
  const [checkCount, setCheckCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const seeInstructions = () => {
    window.open(UrlHelper.home('/mydataninja-integration/', '_blank'));
  };

  const verifyNow = () => {
    window.open('https://' + website.domain, '_blank');
    setLoading(true);
    setCheckInterval(() =>
      setInterval(function () {
        refetch();
        setCheckCount((c) => c + 1);
      }, 2000)
    );
  };

  useEffect(() => {
    if (website?.verified || checkCount > 30) {
      setLoading(false);
      checkInterval && clearInterval(checkInterval);
    }
  }, [website, checkInterval, checkCount]);

  if (isLoading || !website) return null;

  return (
    <Result
      status={website.verified ? 'success' : 'warning'}
      icon={loading ? <Spin tip='Verifying' size='large' /> : undefined}
      title={
        <Input.TextArea
          suffix={<Button shape='circle' icon={<TrashIcon />} />}
          rows={5}
          value={website.script}
          className=' !text-sm'
        />
      }
      subTitle={
        <Typography className='text-sm font-normal text-gray'>
          {website.verified
            ? 'Website is verified, you can now start tracking your website traffic'
            : 'Website is not verified, place the script on your website and then click on "Verify Now" to proceed verification'}
        </Typography>
      }
      extra={[
        <Button onClick={seeInstructions} key='see_intructions' type='link'>
          See Instructions
        </Button>,
        !website.verified && (
          <Button onClick={verifyNow} type='primary' key='verify'>
            Verify Now
          </Button>
        ),
      ]}
    />
  );
};

export default WebsiteVerifier;
