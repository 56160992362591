import React from 'react';
import { Page } from 'components/layouts/components';
import ConnectedWebsitesCard from 'modules/website/components/ConnectedWebsites';

const WebsiteLayout = (props) => {
  return (
    <Page>
      <div className='grid grid-cols-12 gap-4 items-start'>
        <div className='flex col-span-12 md:col-span-3'>
          <ConnectedWebsitesCard />
        </div>
        <div className='flex col-span-12 md:col-span-9'>{props.children}</div>
      </div>
    </Page>
  );
};

export default WebsiteLayout;
