import React from 'react';
import WebsiteForm from 'modules/website/forms/WebsiteForm/WebsiteForm';
import { useCreateWebsiteMutation } from 'modules/DomainModule/api/website.api';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import P from 'router/paths';
import { snackbar } from 'components/notifications/notifications';

const CreateWebsiteForm = (props) => {
  const [createWebsiteQuery, { isLoading }] = useCreateWebsiteMutation();
  const history = useHistory();

  const handleSubmit = (values) => {
    createWebsiteQuery(values)
      .unwrap()
      .then((data) => {
        snackbar.success('Website created successfully');

        if (data.id) {
          history.push(P.WEBSITES.EDIT(data.id));
        }
      });
  };

  return (
    <WebsiteForm
      onSubmit={handleSubmit}
      loading={isLoading}
      initialValues={{
        domain: '',
        subdomains: [],
        aliases: [],
      }}
    />
  );
};

export default CreateWebsiteForm;
