import { useGetWebsiteQuery } from 'modules/DomainModule/api/website.api';

const useWebsite = (id, options = {}) => {
  const { data, isLoading, isFetching, refetch } = useGetWebsiteQuery(id, options);

  return {
    website: data,
    isLoading: isLoading,
    refetch,
  };
};

export default useWebsite;
