import React from 'react';
import PropTypes from 'prop-types';
import WebsiteLayout from 'modules/website/layouts/Website.layout';
import useWebsite from 'modules/website/hooks/useWebsite';
import useWebsites from 'modules/website/hooks/useWebsites';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import P from 'router/paths';

const WebsiteIndexPage = (props) => {
  const { websites, isLoading } = useWebsites();

  return <Redirect to={P.WEBSITES.CREATE} />;

  return <WebsiteLayout></WebsiteLayout>;
};

export default WebsiteIndexPage;
