import ninjaApi from 'store/redux/apis/ninja.api';
import { socketEvents } from 'utils/socket/socket-events';

window.socketEvents = socketEvents;

/**
 * Returns workspace
 */
export const networkAccountsApi = ninjaApi.injectEndpoints({
  overrideExisting: true,
  tagTypes: ['DefaultWorkspace', 'NetworkAccount', 'Reporting'],
  endpoints: (build) => ({
    /* Fetch all workspace accounts */
    networkAccountsList: build.query({
      query: (body) => ({
        url: '/network/accounts',
        method: 'POST',
        body: body,
      }),
      providesTags: ['NetworkAccount'],
      onCacheEntryAdded: async (arg, { dispatch }) => {
        // Network account state changed
        socketEvents.on('network_account:state-changed', (data) => {
          if (!data.account) return;

          dispatch(
            ninjaApi.util.updateQueryData('networkAccountsList', arg, (draft) => {
              const ind = draft.findIndex((acc) => acc.id == data.account.id);
              if (ind === -1) return;
              draft[ind] = data.account;
            })
          );
        });

        // Network account status changed
        socketEvents.on('network_account:status-changed', (data) => {
          if (!data.account) return;

          dispatch(
            ninjaApi.util.updateQueryData('networkAccountsList', arg, (draft) => {
              const ind = draft.findIndex((acc) => acc.id == data.account.id);
              if (ind === -1) return;
              draft[ind] = data.account;
            })
          );
        });
      },
    }),
    /* Get network accounts by batch */
    getAccountsByBatch: build.query({
      query: (data) => ({
        url: '/network/accounts/init/batch/' + data.batch,
        method: 'POST',
        data,
      }),
      providesTags: ['NetworkAccountBatch'],
    }),
    /* Get network accounts by batch */
    getBatchConnectedAccounts: build.query({
      query: (data) => ({
        url: '/network/accounts/init/batch/' + data.batch + '/accounts',
        method: 'POST',
        data,
      }),
      providesTags: ['NetworkAccountBatch'],
    }),
    /* Get network accounts by batch */
    setupAccount: build.mutation({
      query: (data) => ({
        url: '/network/accounts/init/setup',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['NetworkAccountBatch'],
    }),
    /* Skip account setup */
    skipAccountSetup: build.mutation({
      query: (data) => ({
        url: '/network/accounts/init/skip',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['NetworkAccountBatch'],
    }),
    /* Connect new account in the batch */
    connectAccount: build.mutation({
      query: (data) => ({
        url: '/network/accounts/init/connect',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['NetworkAccountBatch', 'NetworkAccount'],
    }),
    /* Connect new account in the batch */
    disconnectAccount: build.mutation({
      query: (data) => ({
        url: '/network/accounts/init/disconnect',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['NetworkAccountBatch'],
    }),
    /* Get single account */
    getNetworkAccount: build.query({
      query: (body) => ({
        url: `/network/accounts/${body.id}`,
        method: 'GET',
        body,
      }),
      providesTags: ['NetworkAccount'],
    }),

    /* Change Network account status */
    changeNetworkAccountStatus: build.mutation({
      query: (body) => ({
        url: '/network/accounts/status',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['NetworkAccount'],
    }),
    /** Delete Network Account */
    deleteNetworkAccount: build.mutation({
      query: (data) => ({
        url: '/network/accounts',
        method: 'DELETE',
        data,
      }),
      invalidatesTags: ['NetworkAccount'],
    }),
    /** Update single Network Account */
    updateNetworkAccount: build.mutation({
      query: (data) => ({
        url: `/network/accounts`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['NetworkAccount'],
    }),
    /** Start Automatic tracking for networkaccount */
    startAutomaticTracking: build.mutation({
      query: (data) => ({
        url: `/network/accounts/tracking/auto`,
        method: 'POST',
        data,
      }),
      invalidatesTags: ['NetworkAccount'],
    }),
    /* Login facebook Account */
    connectFacebook: build.mutation({
      query: (data) => ({
        url: '/auth/connect/facebook',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['NetworkAccount'],
    }),
    /* Login google Account */
    connectGoogle: build.mutation({
      query: (data) => ({
        url: '/auth/connect/google',
        method: 'POST',
        data,
      }),
      invalidatesTags: ['NetworkAccount'],
    }),
  }),
});

export const {
  useNetworkAccountsListQuery,
  useGetNetworkAccountQuery,
  useUpdateNetworkAccountMutation,
  useChangeNetworkAccountStatusMutation,
  useConnectFacebookMutation,
  useConnectGoogleMutation,
  useCreateDefaultConversionsMutation,
  useDeleteNetworkAccountMutation,
  useGetAccountsByBatchQuery,
  useConnectAccountMutation,
  useGetBatchConnectedAccountsQuery,
  useGetBatchAccountsQuery,
  useSetupAccountMutation,
  useSkipAccountSetupMutation,
  useDisconnectAccountMutation,
  useStartAutomaticTrackingMutation,
} = networkAccountsApi;
