import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'antd';
import ConnectedWebsitesList from 'modules/website/components/ConnectedWebsitesList';
import CreateIcon from 'components/icons/CreateIcon';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import P from 'router/paths';

const ConnectedWebsitesCard = (props) => {
  const { listProps, title = 'Connected Websites', ...rest } = props;

  return (
    <Card className='w-full' title={title} {...rest}>
      <ConnectedWebsitesList {...listProps} />

      <div className='w-full flex-center'>
        <Link to={P.WEBSITES.CREATE}>
          <Button icon={<CreateIcon />} type='primary' className='my-4'>
            Create New
          </Button>
        </Link>
      </div>
    </Card>
  );
};

export default ConnectedWebsitesCard;
