import React from 'react';
import { Button } from 'antd';
import NetworkIcon from 'components/NetworkIcon';
import { snackbar } from 'components/notifications/notifications';
import useHubSpotLogin from 'modules/network-accounts/hooks/useHubSpotLogin';
import useAccountConnectionFlow from 'components/AccountConnectionFlow/AccountConnectionFlow.provider';

const ConnectHubSpotStep = () => {
  const flow = useAccountConnectionFlow();
  const { loading: authLoading, openLogin: startHubSpotLoginDialog } = useHubSpotLogin();

  const connectHubSpot = async () => {
    const loginWindow = await snackbar.promise(() => startHubSpotLoginDialog(), {
      loadingMessage: 'Waiting for HubSpot Login...',
      successMessage: 'Successfully Logged in',
      errorMessage: 'Error while login to HubSpot',
    });

    return await new Promise((res, rej) => {
      const onConnectionBatch = (batch) => {
        console.log(batch, 'batch');
        flow.setConnectionBatch(batch);
        res(batch);
        loginWindow.close();
        snackbar.success('Successfully connected HubSpot accounts');
        flow.changeStep('choose_accounts');
      };

      window.addEventListener('message', (event) => {
        console.log(event, 'event');
        try {
          if (event.data?.message !== 'mydataninja_hubspot_connected') {
            return;
          }

          if (!event.data.integration) {
            throw new Error('Error while getting connection batch: ' + JSON.stringify(event.data));
          }

          onConnectionBatch(event.data.integration);
        } catch (err) {
          console.log(err)
          snackbar.error('Error while connecting HubSpot accounts, concact support');
        }
      });
    });
  };

  return (
    <div className='w-full flex-center pt-8'>
      <Button
        onClick={connectHubSpot}
        type='dashed'
        size='large'
        loading={authLoading}
        icon={<NetworkIcon network='hubspot' />}
      >
        {authLoading
          ? 'Please Finish connection or refresh'
          : 'Click Here To Connect Your HubSpot Accounts'}
      </Button>
    </div>
  );
};

ConnectHubSpotStep.propTypes = {};

export default ConnectHubSpotStep;
