import React from 'react';
import WebsiteForm from 'modules/website/forms/WebsiteForm/WebsiteForm';
import useWebsite from 'modules/website/hooks/useWebsite';
import { Skeleton } from 'antd';
import { useUpdateWebsiteMutation } from 'modules/DomainModule/api/website.api';
import { snackbar } from 'components/notifications/notifications';

const EditWebsiteForm = (props) => {
  const { id } = props;
  const { website, isLoading } = useWebsite(id, {
    refetchOnMountOrArgChange: true,
  });

  const [updateWebsiteQuery, { isLoading: isUpdateLoading }] = useUpdateWebsiteMutation();

  if (isLoading) {
    return <Skeleton />;
  }

  const handleSubmit = (values) => {
    updateWebsiteQuery({ id, ...values })
      .unwrap()
      .then((data) => {
        snackbar.success('Website updated successfully');
      });
  };

  return (
    <WebsiteForm
      key={id}
      onSubmit={handleSubmit}
      disabled={isLoading}
      editing
      loading={isUpdateLoading}
      initialValues={{
        domain: website?.domain,
        id: website?.id,
        is_subdomain: website?.is_subdomain,
        is_alias: website?.is_alias,
        subdomains: website?.subdomains.map((subdomain) => ({
          domain: subdomain.domain,
          enable_tracking: subdomain.enable_tracking,
        })),
        aliases: website?.aliases.map((subdomain) => ({
          domain: subdomain.domain,
          enable_tracking: subdomain.enable_tracking,
        })),
      }}
    />
  );
};

export default EditWebsiteForm;
