import React from 'react';
import AutomaticDrawer from 'components/Drawers/AutomaticDrawer';
import useQueryParams from 'hooks/useQueryParams';
import HubSpotConnectionFlow from '../components/HubSpotConnectionFlow/HubSpotConnectionFlow';
import { AccountConnectionFlowContextProvider } from 'components/AccountConnectionFlow/AccountConnectionFlow.provider';

const HubSpotAppDrawer = (props) => {
  return (
    <AutomaticDrawer size='large' title='Connect HubSpot Account' width='80%' {...props}>
      <AccountConnectionFlowContextProvider>
        <div className='flex flex-col'>
          <HubSpotConnectionFlow />
        </div>
      </AccountConnectionFlowContextProvider>
    </AutomaticDrawer>
  );
};

HubSpotAppDrawer.useDrawer = () => {
  const { setUrlParams, removeParams } = useQueryParams();

  const open = () => setUrlParams({ app: 'hubspot' });
  const close = () => removeParams(['app']);

  return {
    open,
    close,
  };
};

HubSpotAppDrawer.requiredParams = {
  app: (app) => 'hubspot' === app,
};

HubSpotAppDrawer.closeParams = ['connection_batch', 'step'];

export default HubSpotAppDrawer;
