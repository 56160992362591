import React from 'react';
import NameDefinition from './NameDefinition';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import { mdiFinance } from '@mdi/js';

const RoiDefinition = ({
  showIcon,
  textFormat = 'text-xs font-semibold w-fit text-background-dark',
}) => {
  return (
    <div className='flex items-center gap-2'>
      {showIcon && <Icon path={mdiFinance} size={0.8} className='text-orange' />}
      <NameDefinition name='ROI' definiton='Return on Investment: Net Profit / Ad Spent' textSize={textFormat} />
    </div>
  );
};

export default RoiDefinition;

RoiDefinition.propTypes = {
  showIcon: PropTypes.object,
  textFormat: PropTypes.string,
};
